var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';
import ActionCheck from '@mui/icons-material/CheckCircle';
import AlertError from '@mui/icons-material/ErrorOutline';
import clsx from 'clsx';
import { useTranslate } from 'ra-core';
/**
 * Confirmation dialog
 *
 * @example
 * <Confirm
 *     isOpen={true}
 *     title="Delete Item"
 *     content="Are you sure you want to delete this item?"
 *     confirm="Yes"
 *     confirmColor="primary"
 *     ConfirmIcon=ActionCheck
 *     CancelIcon=AlertError
 *     cancel="Cancel"
 *     onConfirm={() => { // do something }}
 *     onClose={() => { // do something }}
 * />
 */
export var Confirm = function (props) {
    var _a;
    var className = props.className, _b = props.isOpen, isOpen = _b === void 0 ? false : _b, loading = props.loading, title = props.title, content = props.content, _c = props.cancel, cancel = _c === void 0 ? 'ra.action.cancel' : _c, _d = props.confirm, confirm = _d === void 0 ? 'ra.action.confirm' : _d, _e = props.confirmColor, confirmColor = _e === void 0 ? 'primary' : _e, _f = props.ConfirmIcon, ConfirmIcon = _f === void 0 ? ActionCheck : _f, _g = props.CancelIcon, CancelIcon = _g === void 0 ? AlertError : _g, onClose = props.onClose, onConfirm = props.onConfirm, _h = props.translateOptions, translateOptions = _h === void 0 ? {} : _h, sx = props.sx;
    var translate = useTranslate();
    var handleConfirm = useCallback(function (e) {
        e.stopPropagation();
        onConfirm(e);
    }, [onConfirm]);
    var handleClick = useCallback(function (e) {
        e.stopPropagation();
    }, []);
    return (React.createElement(StyledDialog, { className: className, open: isOpen, onClose: onClose, onClick: handleClick, "aria-labelledby": "alert-dialog-title", sx: sx },
        React.createElement(DialogTitle, { id: "alert-dialog-title" }, translate(title, __assign({ _: title }, translateOptions))),
        React.createElement(DialogContent, null, typeof content === 'string' ? (React.createElement(DialogContentText, null, translate(content, __assign({ _: content }, translateOptions)))) : (content)),
        React.createElement(DialogActions, null,
            React.createElement(Button, { disabled: loading, onClick: onClose },
                React.createElement(CancelIcon, { className: ConfirmClasses.iconPaddingStyle }),
                translate(cancel, { _: cancel })),
            React.createElement(Button, { disabled: loading, onClick: handleConfirm, className: clsx('ra-confirm', (_a = {},
                    _a[ConfirmClasses.confirmWarning] = confirmColor === 'warning',
                    _a[ConfirmClasses.confirmPrimary] = confirmColor === 'primary',
                    _a)), autoFocus: true },
                React.createElement(ConfirmIcon, { className: ConfirmClasses.iconPaddingStyle }),
                translate(confirm, { _: confirm })))));
};
Confirm.propTypes = {
    cancel: PropTypes.string,
    className: PropTypes.string,
    confirm: PropTypes.string,
    confirmColor: PropTypes.string,
    ConfirmIcon: PropTypes.elementType,
    CancelIcon: PropTypes.elementType,
    content: PropTypes.node.isRequired,
    isOpen: PropTypes.bool,
    loading: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    sx: PropTypes.any,
};
var PREFIX = 'RaConfirm';
export var ConfirmClasses = {
    confirmPrimary: "".concat(PREFIX, "-confirmPrimary"),
    confirmWarning: "".concat(PREFIX, "-confirmWarning"),
    iconPaddingStyle: "".concat(PREFIX, "-iconPaddingStyle"),
};
var StyledDialog = styled(Dialog, {
    name: PREFIX,
    overridesResolver: function (props, styles) { return styles.root; },
})(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["& .".concat(ConfirmClasses.confirmPrimary)] = {
            color: theme.palette.primary.main,
        },
        _b["& .".concat(ConfirmClasses.confirmWarning)] = {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: alpha(theme.palette.error.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
        _b["& .".concat(ConfirmClasses.iconPaddingStyle)] = {
            paddingRight: '0.5em',
        },
        _b);
});
