import { useEffect } from 'react';
import useGetPermissions from './useGetPermissions';
import { useSafeSetState } from '../util/hooks';
var emptyParams = {};
/**
 * Hook for getting user permissions
 *
 * Calls the authProvider.getPermissions() method asynchronously.
 * If the authProvider returns a rejected promise, returns empty permissions.
 *
 * The return value updates according to the request state:
 *
 * - start: { isLoading: true }
 * - success: { permissions: [any], isLoading: false }
 * - error: { error: [error from provider], isLoading: false }
 *
 * Useful to enable features based on user permissions
 *
 * @param {Object} params Any params you want to pass to the authProvider
 *
 * @returns The current auth check state. Destructure as { permissions, error, isLoading }.
 *
 * @example
 *     import { usePermissions } from 'react-admin';
 *
 *     const PostDetail = props => {
 *         const { isLoading, permissions } = usePermissions();
 *         if (!isLoading && permissions == 'editor') {
 *             return <PostEdit {...props} />
 *         } else {
 *             return <PostShow {...props} />
 *         }
 *     };
 */
var usePermissions = function (params) {
    if (params === void 0) { params = emptyParams; }
    var _a = useSafeSetState({
        isLoading: true,
    }), state = _a[0], setState = _a[1];
    var getPermissions = useGetPermissions();
    useEffect(function () {
        getPermissions(params)
            .then(function (permissions) {
            setState({ isLoading: false, permissions: permissions });
        })
            .catch(function (error) {
            setState({
                isLoading: false,
                error: error,
            });
        });
    }, [getPermissions, params, setState]);
    return state;
};
export default usePermissions;
