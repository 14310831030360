import React from 'react'
import { Button } from 'react-bootstrap'
import { FaInfoCircle } from 'react-icons/fa'
import YouTube from 'react-youtube'
import Icon from '../../icon/icon'
import SideModal from '../../side-modal/side-modal'
import { SideModalCommonProps } from '../types'

type BookmarkTutorialSideModalProps = SideModalCommonProps

const BookmarkTutorialSideModal: React.FC<BookmarkTutorialSideModalProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <SideModal
      isOpen={isOpen}
      onClose={() => undefined}
      title={<Icon icon={<FaInfoCircle />} text="Bookmark Komit.Today!" />}
      hideCloseButton
    >
      <div
        className="text-center d-flex align-items-center justify-content-center"
        style={{ height: '80vh' }}
      >
        <div>
          <YouTube videoId="ZRmDndmFKPA" opts={{ width: 600 }} iframeClassName="iframe-max-width" />
          <Button className="mt-3" onClick={onClose}>
            OK, I understood
          </Button>
        </div>
      </div>
    </SideModal>
  )
}

export default BookmarkTutorialSideModal
