import { eachDayOfInterval, endOfWeek, format, startOfWeek } from 'date-fns'
import React from 'react'
import {
  Datagrid,
  DateField,
  DateInput,
  FunctionField,
  List,
  RadioButtonGroupInput,
  TextField,
  useStore,
} from 'react-admin'

const UserStatsList: React.FC = ({}) => {
  const [listParams] = useStore('users-stats.listParams')
  const firstDate = listParams?.filter?.date ? new Date(listParams?.filter?.date) : new Date()

  return (
    <List
      sort={{ field: 'firstName', order: '1' }}
      filterDefaultValues={{ kind: 'known', date: format(new Date(), 'yyyy-MM-dd') }}
      filters={[
        <DateInput source="date" label="Week" alwaysOn />,
        <RadioButtonGroupInput
          label="Kind"
          source="kind"
          alwaysOn
          choices={[
            { id: 'anonymous', name: 'Anonymous' },
            { id: 'known', name: 'Known' },
            { id: '', name: 'All' },
          ]}
        />,
      ]}
    >
      <Datagrid isRowSelectable={() => false}>
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="email" />
        <DateField source="createdAt" />
        {eachDayOfInterval({
          start: startOfWeek(firstDate, { weekStartsOn: 1 }),
          end: endOfWeek(firstDate, { weekStartsOn: 1 }),
        }).map((date, index) => (
          <FunctionField
            key={date.getTime()}
            label={format(date, 'EEEE dd/MM/yyyy')}
            render={record => {
              const day = record.days[index]
              return day.summary.count.tasks
            }}
          />
        ))}
      </Datagrid>
    </List>
  )
}

export default UserStatsList
