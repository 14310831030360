import React from 'react'
import { Create, NumberInput, required, SimpleForm, TextInput } from 'react-admin'

interface PodcastsCreateProps {}

const PodcastsCreate: React.FC<PodcastsCreateProps> = ({}) => {
  return (
    <Create>
      <SimpleForm>
        <NumberInput source="userDay" validate={[required()]} fullWidth />
        <TextInput source="youtubeId" validate={[required()]} label="Youtube video ID" />
      </SimpleForm>
    </Create>
  )
}

export default PodcastsCreate
