import React from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import Icon from '../icon/icon'

interface SelectFilterProps {
  options: { value?: any; label: string; icon?: React.ReactNode }[]
  setFilter(key: string, value?: any): void
  name: string
  currentValue?: any
}

const SelectFilter: React.FC<SelectFilterProps> = ({ options, setFilter, name, currentValue }) => {
  return (
    <ButtonGroup>
      {options.map((option, index) => (
        <Button
          key={option.value || index}
          size="sm"
          onClick={() => setFilter(name, option.value)}
          variant={currentValue === option.value ? 'secondary' : 'light'}
        >
          {currentValue === option.value && option.icon ? (
            <Icon icon={option.icon} text={option.label} />
          ) : (
            option.label
          )}
        </Button>
      ))}
    </ButtonGroup>
  )
}

export default SelectFilter
