import React from 'react'
import Layout from '../../components/layout/layout'
import Day from '../../components/day/day'
import { useLocation, useNavigate } from 'react-router-dom'
import useAppShorcut from '../../hooks/use-app-shorcut'
import { useQuery } from 'react-query'
import { Paginated } from '@feathersjs/feathers/lib'
import { Day as IDay } from '../../../../backend/src/models/days.model'
import { fetch } from '../../core/queries'
import useStore from '../../store'
import WelcomeSideModal from '../../components/modals/welcome-side-modal'
import BookmarkTutorialSideModal from '../../components/modals/tutorials/bookmark-tutorial-side-modal'
import TasksListSideModal from '../../components/modals/tasks-list-side-modal'
import PodcastSideModal from '../../components/modals/podcast-side-modal'
import { useTodayPodcast } from '../../hooks/use-today-podcast'
import useDayCurrentDate from '../../hooks/use-day-current-date'
import DayToolbar from '../../components/day/day-toolbar'
import { isSameDay } from 'date-fns'
import UserProfileSideModal from '../../components/modals/user-profile-side-modal'

const Home: React.FC = () => {
  const currentUser = useStore(state => state.session.currentUser)
  const [currentDate, setCurrentDate] = useDayCurrentDate(
    false /*process.env.NODE_ENV === 'development'*/,
  )

  const setUiValue = useStore(state => state.ui.setValue)

  //Is welcome page (url === /welcome)
  const location = useLocation()
  const navigate = useNavigate()
  const isWelcomePage = React.useMemo(() => location.pathname === '/welcome', [location.pathname])
  const isUserProfilePage = React.useMemo(() => location.pathname === '/me', [location.pathname])

  const showBookmarkHelper = !isWelcomePage && !Boolean(localStorage.getItem('bookmark-helper'))

  //Modals states
  const [isPendingTaskListOpen, setIsPendingTaskListOpen] = React.useState(false)
  const [isBookmarkHelperOpen, setIsBookmarkHelperOpen] = React.useState(
    false /*showBookmarkHelper*/,
  )

  //Fetch the current day
  const daysQuery = useQuery<any, unknown, Paginated<IDay>, ['days', any]>(
    ['days', { date: currentDate, user: currentUser?._id }],
    fetch,
  )

  //Get today podcast and visible boolean and fn
  const [todayPodcast, isTodayPodcastIsOpen, closeTodayPodcast, openTodayPodcast] =
    useTodayPodcast(currentUser)

  //POC: shorcuts
  useAppShorcut('t', () => setIsPendingTaskListOpen(!isPendingTaskListOpen))

  const heroIsVisible = useStore(state => state.ui.hero.isVisible)

  const welcomeSeen = Boolean(localStorage.getItem('is-welcome-seen'))

  React.useEffect(() => {
    if (
      currentUser &&
      !welcomeSeen &&
      currentUser?.dailyVideos?.current === 0 &&
      isSameDay(new Date(), new Date(currentUser?.createdAt))
    )
      navigate('/welcome')
  }, [currentUser])

  React.useEffect(() => {
    isWelcomePage && localStorage.setItem('is-welcome-seen', 'yes')
  }, [isWelcomePage])

  const podcastsQuery = useQuery<any, unknown, Paginated<Podcast>, ['podcasts', any]>(
    ['podcasts', { $sort: '-userDay', isApp: true, $limit: 100 }], //TODO: is App
    fetch,
  )

  return (
    <Layout hero showPodcast={openTodayPodcast}>
      <DayToolbar
        setCurrentDate={setCurrentDate}
        currentDate={currentDate}
        openTaskslist={() => setIsPendingTaskListOpen(true)}
        showPodcast={
          todayPodcast && isSameDay(currentDate, new Date()) ? openTodayPodcast : undefined
        }
      />
      <div className="my-4">
        <Day day={daysQuery.data?.data[0]} date={currentDate} />
      </div>
      {(todayPodcast || podcastsQuery.data?.data.length) && (
        <PodcastSideModal
          isOpen={!isWelcomePage && !heroIsVisible && isTodayPodcastIsOpen}
          onClose={closeTodayPodcast}
          podcast={todayPodcast}
          title={`Day ${todayPodcast?.userDay}`}
          titleSmall={'The first day of the rest of your life'}
        />
      )}
      <BookmarkTutorialSideModal
        isOpen={!isWelcomePage && isBookmarkHelperOpen}
        onClose={() => {
          localStorage.setItem('bookmark-helper', '1')
          setIsBookmarkHelperOpen(false)
        }}
      />
      <WelcomeSideModal
        isOpen={!heroIsVisible && isWelcomePage}
        onClose={() => navigate('/')}
        openLogin={() => currentUser?.kind !== 'known' && setUiValue('login.modalIsVisible', true)}
      />
      <UserProfileSideModal isOpen={isUserProfilePage} onClose={() => navigate('/')} />
      <TasksListSideModal
        isOpen={isPendingTaskListOpen}
        onClose={() => setIsPendingTaskListOpen(false)}
        date={currentDate}
        day={daysQuery.data?.data[0]}
      />
    </Layout>
  )
}

export default Home
