import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Header from '../header/header'
//@ts-ignore
import logo from '../../images/komit-logo.png'
import { FaAngleDoubleDown } from 'react-icons/fa'
import useStore from '../../store'
import { User } from '../../../../backend/src/models/users.model'
import { format, isSameDay } from 'date-fns'
import { usePageVisibility } from 'react-page-visibility'
import { BooleanArraySupportOption } from 'prettier'
import { useCustomMutation } from '../../core/queries'
import LoginSideModal from '../modals/login-side-modal'
import { addListener } from 'process'

interface LayoutProps {
  hideFooter?: BooleanArraySupportOption
  hero?: boolean
  showPodcast?(): void
}

const Layout: React.FC<LayoutProps> = ({ children, hero, hideFooter, showPodcast }) => {
  const currentUser = useStore(state => state.session.currentUser as User | undefined)
  const contentRef = React.useRef(null)
  const isVisible = usePageVisibility()
  const setUiValue = useStore(state => state.ui.setValue)

  const loginModalIsVisible = useStore(state => state.ui.login.modalIsVisible)

  //Check if user have already see the hero (default state)
  const isConnetedToday = localStorage.getItem('is-conneted-today')
  const [showHero, setShowHero] = React.useState(
    Boolean((!isConnetedToday || !isSameDay(new Date(isConnetedToday), new Date())) && hero),
  )

  React.useEffect(() => {
    if (currentUser && currentUser.kind === 'known') setUiValue('login.modalIsVisible', false)
  }, [currentUser])

  //Store show erro value in zustand
  React.useEffect(() => {
    setUiValue('hero.isVisible', showHero)
  }, [showHero])

  //Store today to local storage (use for display hero & refresh on come back)
  React.useEffect(() => {
    localStorage.setItem('is-conneted-today', format(new Date(), 'yyyy-MM-dd'))
  }, [])

  //Refresh when user return on page and the day has changed
  React.useEffect(() => {
    if (!isVisible) return
    const lastConnectionDate = localStorage.getItem('is-conneted-today') as string | undefined
    if (lastConnectionDate && lastConnectionDate !== format(new Date(), 'yyyy-MM-dd')) {
      localStorage.setItem('is-conneted-today', format(new Date(), 'yyyy-MM-dd'))
      location.reload()
    }
  }, [isVisible])

  React.useEffect(() => {
    function onScroll() {
      const currentYPosition = window.pageYOffset // or use document.documentElement.scrollTop;
      if (
        showHero &&
        contentRef?.current &&
        (currentYPosition + 60 >= contentRef.current.offsetTop ||
          window.innerHeight + window.pageYOffset + 30 >= document.body.offsetHeight)
      ) {
        setShowHero(false)
      }
    }

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  const setPodcastSeenForUserMutation = useCustomMutation<{ userId: string }>(
    'podcasts',
    'setSeenForUser',
  )

  React.useEffect(() => {
    if (
      !showHero &&
      currentUser &&
      (!currentUser.dailyVideos?.date ||
        !isSameDay(new Date(), new Date(currentUser.dailyVideos?.date)))
    ) {
      setPodcastSeenForUserMutation.mutate({ userId: currentUser._id })
    }
  }, [showHero, currentUser?._id])

  return (
    <>
      {showHero && (
        <div className="text-center" id="app-hero">
          <div
            className="d-flex flex-column py-2 px-5"
            style={{
              height: '90vh',
              backgroundSize: 'cover',
              backgroundImage: `url(${process.env.API_HOST_NAME}/days-pictures/${format(
                new Date(),
                'yyyy-MM-dd',
              )}.jpg)`,
            }}
          >
            <div className="me-auto p-2">
              <a href="/">
                <img src={logo} style={{ height: 70 }} />
              </a>
            </div>
            <div
              className="d-flex align-items-center text-light h-100 justify-content-center fw-bold"
              style={{ fontSize: 68 }}
            >
              <div /*style={{ backgroundColor: '#00000059', borderRadius: '0.5rem' }} className="p-4"*/
              >
                Good {format(new Date(), 'BBBB').replace('in the', '').replace('at ', '')} <br />{' '}
                {currentUser && currentUser.firstName}
              </div>
            </div>
            <div className="mt-auto p-2 text-center text-light fs-4">
              <a href="#app-content" className="link-light text-decoration-none">
                Welcome today
                <br /> <FaAngleDoubleDown />
              </a>
            </div>
          </div>
        </div>
      )}
      <div ref={contentRef} id="app-content">
        <Container fluid="xxl">
          {!showHero ? (
            <Header hideLogo={showHero} showPodcast={showPodcast} />
          ) : (
            <div className="mt-5"></div>
          )}
          {children}
          {!hideFooter && (
            <Row>
              <Col className="text-center pb-4">
                <Link
                  to="/privacy-policy"
                  className="text-secondary text-decoration-none text-muted"
                >
                  Our privacy policy
                </Link>
              </Col>
            </Row>
          )}
          <LoginSideModal
            isOpen={loginModalIsVisible}
            onClose={() => setUiValue('login.modalIsVisible', false)}
          />
        </Container>
      </div>
    </>
  )
}

export default Layout
