import set from 'lodash/set';
import unset from 'lodash/unset';
import get from 'lodash/get';
/**
 * Store using memory
 *
 * @example
 *
 * import { memoryStore } from 'react-admin';
 *
 * const App = () => (
 *    <Admin store={memoryStore()}>
 *       ...
 *   </Admin>
 * );
 */
export var memoryStore = function (storage) {
    if (storage === void 0) { storage = {}; }
    var subscriptions = {};
    var publish = function (key, value) {
        Object.keys(subscriptions).forEach(function (id) {
            if (!subscriptions[id])
                return; // may happen if a component unmounts after a first subscriber was notified
            if (subscriptions[id].key === key) {
                subscriptions[id].callback(value);
            }
        });
    };
    return {
        setup: function () { },
        teardown: function () {
            Object.keys(storage).forEach(function (key) { return delete storage[key]; });
        },
        getItem: function (key, defaultValue) {
            return get(storage, key, defaultValue);
        },
        setItem: function (key, value) {
            set(storage, key, value);
            publish(key, value);
        },
        removeItem: function (key) {
            unset(storage, key);
            publish(key, undefined);
        },
        reset: function () {
            Object.keys(storage).forEach(function (key) {
                unset(storage, key);
                publish(key, undefined);
            });
        },
        subscribe: function (key, callback) {
            var id = Math.random().toString();
            subscriptions[id] = {
                key: key,
                callback: callback,
            };
            return function () {
                delete subscriptions[id];
            };
        },
    };
};
