import React from 'react'
import { Form, Row } from 'react-bootstrap'

export interface FormFieldProps {
  label?: string
  error?: string
  info?: string
}

const FormField: React.FC<FormFieldProps> = ({ label, children, info, error }) => {
  return (
    <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
      <Form.Label>{label}</Form.Label>
      {children}
      {info && <Form.Text className="px-0 text-end">{info}</Form.Text>}
      {error && <Form.Text className="px-0 text-danger">{error}</Form.Text>}
    </Form.Group>
  )
}

export default FormField
