import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { FaBars, FaVideo } from 'react-icons/fa'
import DaySliderSelector from '../day-slider-selector/day-slider-selector'
import Icon from '../icon/icon'

interface DayToolbarProps {
  setCurrentDate(d: Date): void
  currentDate: Date
  openTaskslist(): void
  showPodcast?(): void
}

const DayToolbar: React.FC<DayToolbarProps> = ({
  setCurrentDate,
  currentDate,
  openTaskslist,
  showPodcast,
}) => {
  return (
    <Row>
      <Col md={2} xs={0}></Col>
      <Col md={8} xs={12} className="text-center">
        <DaySliderSelector
          onChange={setCurrentDate}
          date={currentDate}
          /*actions={[
            showPodcast && (
              <Button
                onClick={e => {
                  e.stopPropagation()
                  showPodcast()
                }}
                as="a"
                size="sm"
                variant="link"
                key="video"
                className="p-0"
              >
                <FaVideo />
              </Button>
            ),
          ]}*/
        />
      </Col>
      <Col md={2} xs={12} className="text-end align-self-end">
        <Button
          onClick={e => {
            openTaskslist()
            //@ts-ignore
            e.target.blur()
          }}
          data-side-modal
          variant="light"
          className="text-secondary "
        >
          <Icon icon={<FaBars />} text="For later" />
        </Button>
      </Col>
    </Row>
  )
}

export default DayToolbar
