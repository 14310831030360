import React from 'react'
import {
  Datagrid,
  DateField,
  EditButton,
  EmailField,
  FunctionField,
  ImageField,
  List,
  ShowButton,
  TextField,
} from 'react-admin'
import { Link } from 'react-router-dom'

const PodcastsList: React.FC = ({}) => {
  return (
    <List>
      <Datagrid>
        <TextField source="userDay" />
        <TextField source="youtubeId" />
        <DateField source="createdAt" />
        <FunctionField
          label="Link"
          render={record => {
            const link = `https://www.youtube.com/watch?v=${record.youtubeId}`
            return (
              <a href={link} target="__blank">
                {link}
              </a>
            )
          }}
        />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default PodcastsList
