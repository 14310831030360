var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSaveContext } from '../controller';
import { useRecordContext } from '../controller';
import getFormInitialValues from './getFormInitialValues';
import { getSimpleValidationResolver, } from './getSimpleValidationResolver';
import { setSubmissionErrors } from './setSubmissionErrors';
import { useNotify } from '../notification';
import { useIsFormInvalid } from './useIsFormInvalid';
import { useWarnWhenUnsavedChanges } from './useWarnWhenUnsavedChanges';
/**
 * Wrapper around react-hook-form's useForm
 *
 * This hook adds the following features to react-hook-form's useForm:
 *
 * - form initialization based on RecordContext
 * - validation based on a validate function
 * - notification on invalid form
 * - stop form submission event propagation
 */
export var useAugmentedForm = function (props) {
    var context = props.context, _a = props.criteriaMode, criteriaMode = _a === void 0 ? 'firstError' : _a, defaultValues = props.defaultValues, delayError = props.delayError, formRootPathname = props.formRootPathname, mode = props.mode, resolver = props.resolver, _b = props.reValidateMode, reValidateMode = _b === void 0 ? 'onChange' : _b, onSubmit = props.onSubmit, shouldFocusError = props.shouldFocusError, shouldUnregister = props.shouldUnregister, shouldUseNativeValidation = props.shouldUseNativeValidation, warnWhenUnsavedChanges = props.warnWhenUnsavedChanges, validate = props.validate;
    var record = useRecordContext(props);
    var saveContext = useSaveContext();
    var defaultValuesIncludingRecord = useMemo(function () { return getFormInitialValues(defaultValues, record); }, [JSON.stringify({ defaultValues: defaultValues, record: record })] // eslint-disable-line
    );
    var finalResolver = resolver
        ? resolver
        : validate
            ? getSimpleValidationResolver(validate)
            : undefined;
    var form = useForm({
        context: context,
        criteriaMode: criteriaMode,
        defaultValues: defaultValuesIncludingRecord,
        delayError: delayError,
        mode: mode,
        reValidateMode: reValidateMode,
        resolver: finalResolver,
        shouldFocusError: shouldFocusError,
        shouldUnregister: shouldUnregister,
        shouldUseNativeValidation: shouldUseNativeValidation,
    });
    // initialize form with record
    useEffect(function () {
        if (!record) {
            return;
        }
        var initialValues = getFormInitialValues(defaultValues, record);
        form.reset(initialValues);
    }, [form.reset, JSON.stringify(record, defaultValues)]); // eslint-disable-line react-hooks/exhaustive-deps
    // notify on invalid form
    var isInvalid = useIsFormInvalid(form.control);
    var notify = useNotify();
    useEffect(function () {
        if (isInvalid) {
            notify('ra.message.invalid_form', { type: 'warning' });
        }
    }, [isInvalid, notify]);
    // warn when unsaved change
    useWarnWhenUnsavedChanges(warnWhenUnsavedChanges, formRootPathname, form.control);
    // submit callbacks
    var handleSubmit = useCallback(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var errors;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!onSubmit) return [3 /*break*/, 2];
                    return [4 /*yield*/, onSubmit(values)];
                case 1:
                    errors = _a.sent();
                    _a.label = 2;
                case 2:
                    if (!(onSubmit == null && (saveContext === null || saveContext === void 0 ? void 0 : saveContext.save))) return [3 /*break*/, 4];
                    return [4 /*yield*/, saveContext.save(values)];
                case 3:
                    errors = _a.sent();
                    _a.label = 4;
                case 4:
                    if (errors != null) {
                        setSubmissionErrors(errors, form.setError);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [form, onSubmit, saveContext]);
    var formHandleSubmit = useCallback(function (event) {
        if (!event.defaultPrevented) {
            // Prevent outer forms to receive the event
            event.stopPropagation();
            form.handleSubmit(handleSubmit)(event);
        }
        return;
    }, [form, handleSubmit]);
    return {
        form: form,
        handleSubmit: handleSubmit,
        formHandleSubmit: formHandleSubmit,
        isInvalid: isInvalid,
    };
};
