import React from 'react'

const useAppShorcut = (key: string, onPress: () => void): void => {
  const handleKeyPress = React.useCallback(
    event => {
      if (event.key === key && document.activeElement.getAttributeNames().includes('data-shortcut'))
        onPress()
    },
    [key, onPress],
  )

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])
}

export default useAppShorcut
