import { Paginated } from '@feathersjs/feathers/lib'
import React from 'react'
import { Row, Col, Button, Image, Dropdown, Badge } from 'react-bootstrap'
import { FaVideo } from 'react-icons/fa'
import { useQuery } from 'react-query'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { Podcast } from '../../../../backend/src/models/podcasts.model'
import { User } from '../../../../backend/src/models/users.model'
import { fetch } from '../../core/queries'
import { useTodayPodcast } from '../../hooks/use-today-podcast'
//@ts-ignore
import logo from '../../images/komit-logo.png'
import useStore from '../../store'

interface HeaderProps {
  hideLogo?: boolean
  showPodcast?(): void
}

const Header: React.FC<HeaderProps> = ({ hideLogo, showPodcast }) => {
  const currentUser = useStore(state => state.session.currentUser as User)
  const logout = useStore(state => state.session.logout)
  const setUiValue = useStore(state => state.ui.setValue)
  const [todayPodcast] = useTodayPodcast(currentUser)
  const navigate = useNavigate()

  const podcastsQuery = useQuery<any, unknown, Paginated<Podcast>, ['podcasts', any]>(
    ['podcasts', { $sort: '-userDay', isApp: true, $limit: 100 }], //TODO: is App
    fetch,
  )

  return (
    <Row id="header">
      <Col md={12} className="mt-3">
        <div className="d-flex align-items-center">
          <div className="me-auto p-2">
            <a href="/">{!hideLogo && <img src={logo} style={{ height: 70 }} />}</a>
          </div>
          <div className="pe-0">
            {!currentUser ||
              (currentUser.kind === 'anonymous' && (
                <Button
                  variant="light"
                  // as="a"
                  onClick={e => {
                    setUiValue('login.modalIsVisible', true)
                    e.preventDefault()
                  }}
                  // href={`${process.env.API_HOST_NAME}/oauth/google?feathers-token=${accessToken}`}
                  className="text-primary fw-bold fs-5"
                >
                  Sign up
                </Button>
              ))}
            {currentUser && currentUser.kind === 'known' && (
              <>
                <Button
                  variant="default"
                  onClick={() => {
                    todayPodcast || podcastsQuery.data?.data[0]
                      ? showPodcast && showPodcast()
                      : navigate('/welcome')
                  }}
                  className="me-2"
                >
                  <FaVideo />
                </Button>

                <Dropdown style={{ display: 'inline-block' }}>
                  <Dropdown.Toggle variant="light">
                    {currentUser.picture ? (
                      <Image roundedCircle src={currentUser.picture} width={30}></Image>
                    ) : (
                      <div
                        style={{ width: 40, height: 40 }}
                        className="bg-primary rounded-circle p-2 text-white"
                      >
                        {currentUser.firstName && currentUser.firstName[0]}
                        {currentUser.lastName && currentUser.lastName[0]}
                      </div>
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {currentUser.isAdmin && (
                      <Dropdown.Item as={Link} to="/admin">
                        Admin
                      </Dropdown.Item>
                    )}
                    {/*<Dropdown.Item as={Link} to="/me">
                      Profile
                    </Dropdown.Item>*/}
                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default Header
