import React from 'react'
import { useSearchParams } from 'react-router-dom'
import useStore from '../../store'

const OAuthCallback: React.FC = ({}) => {
  const replaceToken = useStore(state => state.session.replaceToken)
  const [searchParams] = useSearchParams()
  const accessToken = searchParams.get('access_token')

  React.useEffect(() => {
    if (accessToken) {
      replaceToken(accessToken)
    }
  }, [accessToken])

  return <>Redirect...</>
}

export default OAuthCallback
