import { FieldProps } from 'formik'
import React from 'react'
import FormField, { FormFieldProps } from '.'
import useDigitInput from 'react-digit-input'
import { Form } from 'react-bootstrap'

interface DigitsInpuFieldProps extends FormFieldProps, FieldProps {
  length: number
}

const DigitsInputField: React.FC<DigitsInpuFieldProps> = props => {
  const { field, form, type, info, placeholder, noAutoComplete } = props
  if (!props.field.value) props.field.value = ''
  const [value, onChange] = React.useState('')

  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: props.length,
    value: props.field.value,
    onChange,
  })

  React.useEffect(() => {
    form.setFieldValue(field.name, value)
  }, [value])

  return (
    <FormField
      label={props.label}
      error={
        Boolean(form.touched && form.submitCount > 0)
          ? (form.errors[field.name] as string)
          : undefined
      }
      info={info}
    >
      <div className="d-flex justify-content-center">
        {[...Array(props.length).keys()].map((_, index) => (
          <Form.Control
            key={index}
            type={'decimal'}
            {...field}
            {...digits[index]}
            placeholder={placeholder}
            autoComplete={noAutoComplete && 'off'}
            style={{ width: 36, marginRight: 8 }}
          />
        ))}
      </div>
    </FormField>
  )
}

export default DigitsInputField
