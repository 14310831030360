import React from 'react'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import { useQuery } from 'react-query'
import { ListEventsData } from '../../../../backend/src/services/cronofy/cronofy.class'
import { addDays, startOfDay, subDays } from 'date-fns'
import app from '../../core/feathers'
import useStore from '../../store'
import { Button } from 'react-bootstrap'
import { User } from '../../../../backend/src/models/users.model'

interface DayCalendarProps {
  date: Date
}

const DayCalendar: React.FC<DayCalendarProps> = ({ date }) => {
  const calendarComponentRef = React.createRef()
  const currentUser = useStore(state => state.session.currentUser)
  const accessToken = useStore(state => state.session.token)
  const setUiValue = useStore(state => state.ui.setValue)

  React.useEffect(() => {
    //@ts-ignore
    const calendarApi = calendarComponentRef?.current?.getApi()
    calendarApi && calendarApi.gotoDate(date) // call a method on the Calendar object
  }, [date])

  const { data, isLoading } = useQuery<any, unknown, any, ['cronofy', ListEventsData]>(
    ['cronofy', { from: startOfDay(subDays(date, 0)), to: startOfDay(addDays(date, 1)) }],
    async (options): Promise<any> => {
      const [key, query] = options.queryKey
      return await app.service(key).listEvents(query, {
        headers: { Authorization: await app.authentication.getAccessToken() },
      })
    },
  )

  if (!currentUser || currentUser.kind !== 'known')
    return (
      <div className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
        <span className="text-center">
          You need to create first an account to sync your agenda.
          <br />
          <Button
            variant="light"
            //as="a"
            //href={`${process.env.API_HOST_NAME}/oauth/google?feathers-token=${accessToken}`}
            onClick={() => setUiValue('login.modalIsVisible', true)}
            className="text-primary fw-bold fs-5 bg-white border border-0"
          >
            Sign up
          </Button>
        </span>
      </div>
    )
  const user = currentUser as User
  if (!user.providers || !user.providers.cronofy || !user.providers.cronofy.accessToken)
    return (
      <div className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
        <Button
          variant="light"
          as="a"
          href={`${process.env.API_HOST_NAME}/oauth/cronofy?feathers-token=${accessToken}`}
        >
          Sync your agenda
        </Button>
      </div>
    )

  if (isLoading)
    return (
      <div className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
        Loading...
      </div>
    )

  if (!data || data.statusCode >= 400)
    return (
      <div className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
        <Button
          variant="light"
          as="a"
          href={`${process.env.API_HOST_NAME}/oauth/cronofy?feathers-token=${accessToken}`}
        >
          Sync your agenda
        </Button>
      </div>
    )

  return (
    <FullCalendar
      plugins={[timeGridPlugin]}
      initialView="timeGridDay"
      headerToolbar={false}
      initialDate={date}
      ref={calendarComponentRef}
      events={data?.events?.map(e => ({ ...e, title: e.summary }))}
      dayHeaders={false}
      slotMinTime="08:00:00"
      slotMaxTime="22:00:00"
      allDaySlot={false}
      themeSystem={'bootstrap5'}
    />
  )
}

export default DayCalendar
