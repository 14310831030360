import React from 'react'
import { QueryFunctionContext, useMutation, UseMutationResult, useQueryClient } from 'react-query'
import app, { ServiceTypes } from './feathers'

interface MutationOptions {
  otherQueriesToInvalidates?: string[]
}

export const fetch = async <T>( //Can be default query function?
  options: QueryFunctionContext<[keyof ServiceTypes, T]>,
): Promise<T> => {
  const [key, query] = options.queryKey
  return await app.service(key).find({
    query,
  })
}

export const get = async <T>(
  options: QueryFunctionContext<[keyof ServiceTypes, string]>,
): Promise<T> => {
  const [key, id] = options.queryKey
  return await app.service(key).get(id)
}

const useMutationOptions = (serviceName: string, options?: MutationOptions): any => {
  const queryClient = useQueryClient()
  return React.useMemo(
    () => ({
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(serviceName)
        if (options && options.otherQueriesToInvalidates) {
          //TODO check if react query have "default option"
          options.otherQueriesToInvalidates.forEach(key => queryClient.invalidateQueries(key))
        }
      },
    }),
    [queryClient, serviceName, options],
  )
}

export const useFeathersMutation = <D = any, T = any>(
  serviceName: keyof ServiceTypes,
  action: string,
  options?: MutationOptions,
): UseMutationResult<D, unknown, T, unknown> => {
  const mutation = useMutation<D, unknown, T, unknown>((data: any): any => {
    if (action === 'update' || action === 'patch')
      return app.service(serviceName)[action](data._id, data)
    else return app.service(serviceName)[action](data)
  }, useMutationOptions(serviceName, options))

  return mutation
}

//TODO: remove this hook and use generic. PB => Authorization is not used by feathers-client custom action
export const useCustomMutation = <T = any>(
  serviceName: keyof ServiceTypes,
  actionName: string,
  options?: MutationOptions,
): UseMutationResult<unknown, unknown, T, unknown> => {
  const mutation = useMutation(async (data: any): Promise<any> => {
    return app
      .service(serviceName)
      [actionName](data, { headers: { Authorization: await app.authentication.getAccessToken() } })
  }, useMutationOptions(serviceName, options))

  return mutation
}
