import * as React from 'react';
import { cloneElement, useCallback, useMemo, } from 'react';
import { styled } from '@mui/material';
import clsx from 'clsx';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useRecordContext } from 'ra-core';
import { useArrayInput } from './useArrayInput';
import { SimpleFormIteratorClasses, SimpleFormIteratorPrefix, } from './useSimpleFormIteratorStyles';
import { SimpleFormIteratorContext } from './SimpleFormIteratorContext';
import { SimpleFormIteratorItem, } from './SimpleFormIteratorItem';
import { AddItemButton as DefaultAddItemButton } from './AddItemButton';
import { RemoveItemButton as DefaultRemoveItemButton } from './RemoveItemButton';
import { ReOrderButtons as DefaultReOrderButtons } from './ReOrderButtons';
export var SimpleFormIterator = function (props) {
    var _a = props.addButton, addButton = _a === void 0 ? React.createElement(DefaultAddItemButton, null) : _a, _b = props.removeButton, removeButton = _b === void 0 ? React.createElement(DefaultRemoveItemButton, null) : _b, _c = props.reOrderButtons, reOrderButtons = _c === void 0 ? React.createElement(DefaultReOrderButtons, null) : _c, children = props.children, className = props.className, resource = props.resource, source = props.source, disabled = props.disabled, disableAdd = props.disableAdd, disableRemove = props.disableRemove, disableReordering = props.disableReordering, _d = props.getItemLabel, getItemLabel = _d === void 0 ? DefaultLabelFn : _d;
    var _e = useArrayInput(props), append = _e.append, fields = _e.fields, move = _e.move, remove = _e.remove;
    var record = useRecordContext(props);
    var removeField = useCallback(function (index) {
        remove(index);
    }, [remove]);
    var addField = useCallback(function (item) {
        if (item === void 0) { item = undefined; }
        append(item);
    }, [append]);
    // add field and call the onClick event of the button passed as addButton prop
    var handleAddButtonClick = function (originalOnClickHandler) { return function (event) {
        addField();
        if (originalOnClickHandler) {
            originalOnClickHandler(event);
        }
    }; };
    var handleReorder = useCallback(function (origin, destination) {
        move(origin, destination);
    }, [move]);
    var records = get(record, source);
    var context = useMemo(function () { return ({
        total: fields.length,
        add: addField,
        remove: removeField,
        reOrder: handleReorder,
        source: source,
    }); }, [addField, fields.length, handleReorder, removeField, source]);
    return fields ? (React.createElement(SimpleFormIteratorContext.Provider, { value: context },
        React.createElement(Root, { className: className },
            fields.map(function (member, index) { return (React.createElement(SimpleFormIteratorItem, { key: member.id, disabled: disabled, disableRemove: disableRemove, disableReordering: disableReordering, fields: fields, getItemLabel: getItemLabel, index: index, member: "".concat(source, ".").concat(index), onRemoveField: removeField, onReorder: handleReorder, record: (records && records[index]) || {}, removeButton: removeButton, reOrderButtons: reOrderButtons, resource: resource, source: source }, children)); }),
            !disabled && !disableAdd && (React.createElement("li", { className: SimpleFormIteratorClasses.line },
                React.createElement("span", { className: SimpleFormIteratorClasses.action }, cloneElement(addButton, {
                    onClick: handleAddButtonClick(addButton.props.onClick),
                    className: clsx('button-add', "button-add-".concat(source)),
                }))))))) : null;
};
SimpleFormIterator.defaultProps = {
    disableAdd: false,
    disableRemove: false,
};
SimpleFormIterator.propTypes = {
    defaultValue: PropTypes.any,
    addButton: PropTypes.element,
    removeButton: PropTypes.element,
    children: PropTypes.node,
    className: PropTypes.string,
    field: PropTypes.object,
    fields: PropTypes.array,
    fieldState: PropTypes.object,
    formState: PropTypes.object,
    record: PropTypes.object,
    source: PropTypes.string,
    resource: PropTypes.string,
    translate: PropTypes.func,
    disableAdd: PropTypes.bool,
    disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    TransitionProps: PropTypes.shape({}),
};
var Root = styled('ul', {
    name: SimpleFormIteratorPrefix,
    overridesResolver: function (props, styles) { return styles.root; },
})(function (_a) {
    var _b, _c, _d;
    var theme = _a.theme;
    return (_b = {
            padding: 0,
            marginBottom: 0,
            '& > li:last-child': {
                borderBottom: 'none',
            }
        },
        _b["& .".concat(SimpleFormIteratorClasses.line)] = (_c = {
                display: 'flex',
                listStyleType: 'none',
                borderBottom: "solid 1px ".concat(theme.palette.divider)
            },
            _c[theme.breakpoints.down('sm')] = { display: 'block' },
            _c),
        _b["& .".concat(SimpleFormIteratorClasses.index)] = (_d = {},
            _d[theme.breakpoints.down('md')] = { display: 'none' },
            _d.marginRight = theme.spacing(1),
            _d),
        _b["& .".concat(SimpleFormIteratorClasses.indexContainer)] = {
            display: 'flex',
            paddingTop: '1em',
            marginRight: theme.spacing(1),
            alignItems: 'center',
        },
        _b["& .".concat(SimpleFormIteratorClasses.form)] = {
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            flex: 2,
        },
        _b["& .".concat(SimpleFormIteratorClasses.action)] = {
            paddingTop: '0.5em',
        },
        _b["& .".concat(SimpleFormIteratorClasses.leftIcon)] = {
            marginRight: theme.spacing(1),
        },
        _b);
});
var DefaultLabelFn = function (index) { return index + 1; };
