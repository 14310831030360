import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import reactTextareaAutosize from 'react-textarea-autosize'
import { Day } from '../../../../backend/src/models/days.model'
import Card from '../card/card'
import TasksList, { Task } from './day-tasks-list'
import { upperFirst } from 'lodash'
import { useCustomMutation, useFeathersMutation } from '../../core/queries'
import { useIsFetching, useQuery } from 'react-query'
import { ExportTaskPayload } from '../../core/feathers'
import IconImg from '../icon-img/icon-img'
import DayCalendar from './calendar'

interface DayProps {
  day?: Day
  date: Date
}

const Day: React.FunctionComponent<DayProps> = ({ day, date }) => {
  const listTypes = React.useMemo<['pro', 'private']>(() => ['pro', 'private'], [])

  const daysIsFetching = useIsFetching('days')

  const createDay = useFeathersMutation<Partial<Day>>('days', 'create')
  const updateDay = useFeathersMutation<Partial<Day>>('days', 'patch')
  const exportDayTask = useCustomMutation<ExportTaskPayload>('days', 'exportTask')

  const onTasksListChange = (tasksList: Task[], type: 'pro' | 'private') => {
    if (!day) {
      createDay.mutate({
        date,
        tasks: {
          //@ts-ignore
          private: {
            items: [],
          },
          //@ts-ignore
          pro: { items: [] },
          [type]: { items: tasksList },
        },
      })
    } else {
      updateDay.mutate({
        _id: day._id,
        tasks: {
          ...day.tasks,
          [type]: {
            ...day.tasks[type],
            items: tasksList,
          },
        },
      })
    }
  }

  const onNoteChange = (note: string) => {
    if (!day) {
      createDay.mutate({
        date,
        note,
      })
    } else {
      updateDay.mutate({ _id: day._id, note })
    }
  }

  const iconHeight = 32

  return (
    <>
      <Row>
        <Col md={8}>
          <Row>
            {listTypes.map(type => (
              <Col md={6} key={type}>
                <TasksList
                  title={upperFirst(type)}
                  icon={<IconImg icon={type} height={iconHeight} />}
                  defaultLength={4}
                  onChange={tasks => onTasksListChange(tasks, type)}
                  tasks={day?.tasks[type]}
                  loadInProgress={Boolean(!day && daysIsFetching)}
                  date={date}
                  onExport={(task: Task, date?: Date) =>
                    day &&
                    exportDayTask.mutate({
                      taskId: task._id,
                      dayId: day._id,
                      kind: type,
                      date,
                    })
                  }
                />
              </Col>
            ))}
          </Row>
          <Row className="mt-4">
            <Col md={12}>
              <Card
                title="Notes"
                icon={<IconImg icon={'notes'} height={iconHeight} />}
                key={day?._id}
              >
                <Form.Control
                  as={reactTextareaAutosize}
                  placeholder="I am writing here my notes..."
                  defaultValue={day && day.note}
                  onBlur={e => onNoteChange(e.target.value)}
                  minRows={9}
                  maxRows={12}
                  style={{ border: 0, resize: 'none' }}
                />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Card
            title="Agenda"
            icon={<IconImg icon={'calendar'} height={iconHeight} />}
            style={{ height: '100%', overflow: 'hidden' }}
          >
            <DayCalendar date={date} />
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Day
