import React, { ReactNode } from 'react'

interface IconProps {
  icon?: ReactNode
  text?: string
}

const Icon: React.FC<IconProps> = ({ icon, text }) => {
  return (
    <div className="d-flex align-items-center">
      {icon}
      {text && <span className="ms-1">{text}</span>}
    </div>
  )
}

export default Icon
