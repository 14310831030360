var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback } from 'react';
import { useStore } from '../../store';
/**
 * State-like hook for controlling the expanded state of a list item
 *
 * @param {string} resource The resource name, e.g. 'posts'
 * @param {string|integer} id The record identifier, e.g. 123
 * @param {boolean} single Forces only one id to be expanded at a time
 * @returns {Object} Destructure as [expanded, toggleExpanded].
 *
 * @example
 *
 * const [expanded, toggleExpanded] = useExpanded('posts', 123);
 * const expandIcon = expanded ? ExpandLess : ExpandMore;
 * const onExpandClick = () => toggleExpanded();
 */
export var useExpanded = function (resource, id, single) {
    if (single === void 0) { single = false; }
    var _a = useStore("".concat(resource, ".datagrid.expanded"), []), expandedIds = _a[0], setExpandedIds = _a[1];
    var expanded = Array.isArray(expandedIds)
        ? // eslint-disable-next-line eqeqeq
            expandedIds.map(function (el) { return el == id; }).indexOf(true) !== -1
        : false;
    var toggleExpanded = useCallback(function () {
        setExpandedIds(function (ids) {
            if (!Array.isArray(ids)) {
                return [id];
            }
            var index = ids.findIndex(function (el) { return el == id; }); // eslint-disable-line eqeqeq
            return index > -1
                ? single
                    ? []
                    : __spreadArray(__spreadArray([], ids.slice(0, index), true), ids.slice(index + 1), true)
                : single
                    ? [id]
                    : __spreadArray(__spreadArray([], ids, true), [id], false);
        });
    }, [setExpandedIds, id, single]);
    return [expanded, toggleExpanded];
};
