import React from 'react'
import { GiCheckMark, GiHillConquest, GiMushroomHouse, GiPerpendicularRings } from 'react-icons/gi'
import useStore from '../../store'
import SelectFilter from '../filters/select-filter'
import shallow from 'zustand/shallow'
import IconImg from '../icon-img/icon-img'

const TasksListFilters: React.FC = ({}) => {
  const [setFilter, filters] = useStore(
    state => [state.tasks.setFilter, state.tasks.filters],
    shallow,
  )
  return (
    <>
      <SelectFilter
        options={[
          //{ value: undefined, label: 'All' },
          { value: 'pro', label: 'Pro', icon: <IconImg icon="pro" height={16} /> },
          { value: 'private', label: 'Private', icon: <IconImg icon="private" height={16} /> },
        ]}
        name="kind"
        setFilter={setFilter}
        currentValue={filters.kind}
      />
      <SelectFilter
        options={[
          { value: false, label: 'To do', icon: <GiHillConquest /> },
          { value: true, label: 'Done', icon: <GiCheckMark /> },
        ]}
        name="isCompleted"
        setFilter={setFilter}
        currentValue={filters.isCompleted}
      />
    </>
  )
}

export default TasksListFilters
