import { Field, Form, Formik } from 'formik'
import React from 'react'
import { Task } from '../../../../backend/src/models/tasks.model'
import { useFeathersMutation } from '../../core/queries'
import useStore from '../../store'
import InputField from '../form/fields/input'

const TaskForm: React.FC = () => {
  const mutation = useFeathersMutation<Task, Partial<Task>>('tasks', 'create')
  const filters = useStore(state => state.tasks.filters)

  return (
    <Formik
      initialValues={{ text: '' }}
      validate={values => {
        const errors: any = {}
        if (!values.text) errors.text = 'Required field'
        return errors
      }}
      onSubmit={(values, { resetForm }) => {
        mutation.mutate({ ...values, kind: filters.kind })
        resetForm({})
      }}
    >
      <Form>
        <div style={{ minHeight: 80 }}>
          <Field
            name="text"
            type="text"
            component={InputField}
            info="Press ENTER to add"
            placeholder="Add a new komit for later"
            noAutoComplete
          />
        </div>
      </Form>
    </Formik>
  )
}

export default TaskForm
