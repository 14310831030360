import produce from 'immer'
import { StoreSlice, StoreState } from '.'
import { set as _set } from 'lodash'

export interface UISlice {
  hero: {
    isVisible: boolean
  }
  login: {
    modalIsVisible: boolean
  }
  setValue(key: string, value: any): void
}

const uiSlice: StoreSlice<UISlice> = (set, get) => ({
  hero: {
    isVisible: false,
  },
  login: {
    modalIsVisible: false,
  },
  setValue: (key, value) => {
    set(
      produce((state: StoreState) => {
        state = _set(state, `ui.${key}`, value)
      }),
    )
  },
})
export default uiSlice
