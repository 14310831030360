var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@mui/material';
import ContentSave from '@mui/icons-material/Save';
import { useFormContext, useFormState } from 'react-hook-form';
import { useSaveContext, useTranslate, warning, setSubmissionErrors, } from 'ra-core';
/**
 * Submit button for resource forms (Edit and Create).
 *
 * @typedef {Object} Props the props you can use (other props are injected by the <Toolbar>)
 * @prop {string} className
 * @prop {string} label Button label. Defaults to 'ra.action.save', translated.
 * @prop {boolean} disabled Disable the button.
 * @prop {string} variant MUI variant for the button. Defaults to 'contained'.
 * @prop {ReactElement} icon
 * @prop {function} mutationOptions Object of options passed to react-query.
 * @prop {function} transform Callback to execute before calling the dataProvider. Receives the data from the form, must return that transformed data. Can be asynchronous (and return a Promise)
 * @prop {boolean} alwaysEnable Force enabling the <SaveButton>. If it's not defined, the `<SaveButton>` will be enabled using `react-hook-form`'s `isValidating` state props and form context's `saving` prop (disabled if isValidating or saving, enabled otherwise).
 *
 * @param {Props} props
 *
 * @example // with custom success side effect
 *
 * const MySaveButton = props => {
 *     const notify = useNotify();
 *     const redirect = useRedirect();
 *     const onSuccess = (response) => {
 *         notify(`Post "${response.data.title}" saved!`);
 *         redirect('/posts');
 *     };
 *     return <SaveButton {...props} mutationOptions={{ onSuccess }} />;
 * }
 */
export var SaveButton = function (props) {
    var _a = props.color, color = _a === void 0 ? 'primary' : _a, _b = props.icon, icon = _b === void 0 ? defaultIcon : _b, invalid = props.invalid, _c = props.label, label = _c === void 0 ? 'ra.action.save' : _c, onClick = props.onClick, mutationOptions = props.mutationOptions, saving = props.saving, disabledProp = props.disabled, _d = props.type, type = _d === void 0 ? 'submit' : _d, transform = props.transform, _e = props.variant, variant = _e === void 0 ? 'contained' : _e, _f = props.alwaysEnable, alwaysEnable = _f === void 0 ? false : _f, rest = __rest(props, ["color", "icon", "invalid", "label", "onClick", "mutationOptions", "saving", "disabled", "type", "transform", "variant", "alwaysEnable"]);
    var translate = useTranslate();
    var form = useFormContext();
    var saveContext = useSaveContext();
    var _g = useFormState(), isDirty = _g.isDirty, isValidating = _g.isValidating;
    // Use form isDirty, isValidating and form context saving to enable or disable the save button
    // if alwaysEnable is undefined
    var disabled = valueOrDefault(alwaysEnable === false || alwaysEnable === undefined
        ? undefined
        : !alwaysEnable, disabledProp || !isDirty || isValidating || (saveContext === null || saveContext === void 0 ? void 0 : saveContext.saving));
    warning(type === 'submit' &&
        ((mutationOptions &&
            (mutationOptions.onSuccess || mutationOptions.onError)) ||
            transform), 'Cannot use <SaveButton mutationOptions> props on a button of type "submit". To override the default mutation options on a particular save button, set the <SaveButton type="button"> prop, or set mutationOptions in the main view component (<Create> or <Edit>).');
    var handleSubmit = useCallback(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var errors;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(saveContext === null || saveContext === void 0 ? void 0 : saveContext.save)) return [3 /*break*/, 2];
                    return [4 /*yield*/, saveContext.save(values, __assign(__assign({}, mutationOptions), { transform: transform }))];
                case 1:
                    errors = _a.sent();
                    _a.label = 2;
                case 2:
                    if (errors != null) {
                        setSubmissionErrors(errors, form.setError);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [form.setError, saveContext, mutationOptions, transform]);
    var handleClick = useCallback(function (event) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (onClick) {
                        onClick(event);
                    }
                    if (event.defaultPrevented) {
                        return [2 /*return*/];
                    }
                    if (!(type === 'button')) return [3 /*break*/, 2];
                    // this button doesn't submit the form, so it doesn't trigger useIsFormInvalid in <FormContent>
                    // therefore we need to check for errors manually
                    event.stopPropagation();
                    return [4 /*yield*/, form.handleSubmit(handleSubmit)(event)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [onClick, type, form, handleSubmit]);
    var displayedLabel = label && translate(label, { _: label });
    var finalSaving = typeof saving !== 'undefined' ? saving : saveContext === null || saveContext === void 0 ? void 0 : saveContext.saving;
    return (React.createElement(StyledButton, __assign({ variant: variant, type: type, color: color, "aria-label": displayedLabel, disabled: disabled, onClick: handleClick }, rest),
        finalSaving ? React.createElement(CircularProgress, { size: 18, thickness: 2 }) : icon,
        displayedLabel));
};
var defaultIcon = React.createElement(ContentSave, null);
SaveButton.propTypes = {
    className: PropTypes.string,
    invalid: PropTypes.bool,
    label: PropTypes.string,
    saving: PropTypes.bool,
    variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
    icon: PropTypes.element,
    alwaysEnable: PropTypes.bool,
};
var PREFIX = 'RaSaveButton';
var StyledButton = styled(Button, {
    name: PREFIX,
    overridesResolver: function (props, styles) { return styles.root; },
})(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            position: 'relative'
        },
        _b["& .MuiSvgIcon-root, & .MuiIcon-root, & .MuiCircularProgress-root"] = {
            marginRight: theme.spacing(1),
        },
        _b["& .MuiSvgIcon-root, & .MuiIcon-root"] = {
            fontSize: 18,
        },
        _b);
});
var valueOrDefault = function (value, defaultValue) {
    return typeof value === 'undefined' ? defaultValue : value;
};
