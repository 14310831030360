import { current } from 'immer'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useStore from '../store'

interface AuthenticatedProps {
  forAdmin: boolean
}

const Authenticated: React.FC<AuthenticatedProps> = ({ children, forAdmin }) => {
  const currentUser = useStore(state => state.session.currentUser)
  const anonymousAuth = useStore(state => state.session.anonymousAuth)
  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
    if (currentUser && currentUser.kind === 'known' && !currentUser.firstName) navigate('/me')
  }, [currentUser, location.pathname])

  React.useEffect(() => {
    anonymousAuth()
  }, [])

  if (!currentUser) return <></>
  if (currentUser && forAdmin && !currentUser.isAdmin) navigate('/')

  return <>{children}</>
}

export default Authenticated
