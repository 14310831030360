import * as React from 'react';
import { IconButtonWithTooltip } from '../../button';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useSimpleFormIteratorItem } from './useSimpleFormIteratorItem';
export var ReOrderButtons = function (_a) {
    var className = _a.className;
    var _b = useSimpleFormIteratorItem(), index = _b.index, total = _b.total, reOrder = _b.reOrder;
    return (React.createElement("div", { className: className },
        React.createElement(IconButtonWithTooltip, { label: "ra.action.move_up", size: "small", onClick: function () { return reOrder(index - 1); }, disabled: index <= 0 },
            React.createElement(ArrowUpwardIcon, null)),
        React.createElement(IconButtonWithTooltip, { label: "ra.action.move_down", size: "small", onClick: function () { return reOrder(index + 1); }, disabled: total == null || index >= total - 1 },
            React.createElement(ArrowDownwardIcon, null))));
};
