import {
  endOfToday,
  endOfYesterday,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks,
} from 'date-fns'
import React from 'react'
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  ReferenceField,
  SelectInput,
  TextField,
} from 'react-admin'
import Filters from './filters'

const DaysList: React.FC = () => {
  return (
    <List
      sort={{ field: 'date', order: 'DESC' }}
      filters={[
        <SelectInput
          label="Date"
          source="date"
          alwaysOn
          optionValue="date"
          choices={[
            {
              date: { $gte: endOfYesterday().getTime(), $lte: endOfToday().getTime() },
              name: 'Today',
            },
            {
              date: { $gte: startOfWeek(new Date()).toISOString() },
              name: 'This Week',
            },
            {
              date: {
                $gte: subWeeks(startOfWeek(new Date()), 1).toISOString(),
                $lte: startOfWeek(new Date()).toISOString(),
              },
              name: 'Last week',
            },
            {
              date: {
                $gte: subMonths(startOfMonth(new Date()), 1).toISOString(),
                $lte: startOfMonth(new Date()).toISOString(),
              },
              name: 'Last Month',
            },
            { date: { $gte: undefined, $lte: undefined }, name: 'All' },
          ]}
        />,
      ]}
    >
      <Datagrid>
        <ReferenceField label="User" source="user" reference="users" link={false}>
          <>
            <TextField source="firstName" />
            &nbsp;
            <TextField source="lastName" />
          </>
        </ReferenceField>
        <DateField source="date" />
        <FunctionField
          label="Pro"
          render={record => record.tasks.pro.items.filter(i => i.text).length}
        />
        <FunctionField
          label="Private"
          render={record => record.tasks.private.items.filter(i => i.text).length}
        />
      </Datagrid>
    </List>
  )
}
export default DaysList
