import React from 'react'
import { Row } from 'react-bootstrap'
import { FaBars } from 'react-icons/fa'
import { Day } from '../../../../backend/src/models/days.model'
import Icon from '../icon/icon'
import SideModal from '../side-modal/side-modal'
import TasksList from '../tasks/list'
import TasksListFilters from '../tasks/list-filters'
import TaskForm from '../tasks/task-form'
import { SideModalCommonProps } from './types'

interface TasksListSideModalProps extends SideModalCommonProps {
  date: Date
  day?: Day
}

const TasksListSideModal: React.FC<TasksListSideModalProps> = ({ isOpen, onClose, date, day }) => {
  return (
    <SideModal
      isOpen={isOpen}
      onClose={onClose}
      title={<Icon icon={<FaBars />} text="All tasks for later" />}
    >
      <div className="d-flex justify-content-between mb-3">
        <TasksListFilters />
      </div>
      <Row>
        <TaskForm />
      </Row>
      <Row className="mt-5">
        <TasksList date={date} currentDay={day} />
      </Row>
    </SideModal>
  )
}

export default TasksListSideModal
