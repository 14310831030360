import { compose } from './compose.js';
const runHook = (hook, context, type) => {
    if (type)
        context.type = type;
    return Promise.resolve(hook.call(context.self, context))
        .then((res) => {
        if (type)
            context.type = null;
        if (res && res !== context) {
            Object.assign(context, res);
        }
    });
};
export function fromBeforeHook(hook) {
    return (context, next) => {
        return runHook(hook, context, 'before').then(next);
    };
}
export function fromAfterHook(hook) {
    return (context, next) => {
        return next().then(() => runHook(hook, context, 'after'));
    };
}
export function fromErrorHook(hook) {
    return (context, next) => {
        return next().catch((error) => {
            if (context.error !== error || context.result !== undefined) {
                context.original = { ...context };
                context.error = error;
                delete context.result;
            }
            return runHook(hook, context, 'error').then(() => {
                if (context.result === undefined && context.error !== undefined) {
                    throw context.error;
                }
            });
        });
    };
}
export function collect({ before = [], after = [], error = [] }) {
    const beforeHooks = before.map(fromBeforeHook);
    const afterHooks = [...after].reverse().map(fromAfterHook);
    const errorHooks = error.map(fromErrorHook);
    return compose([...errorHooks, ...afterHooks, ...beforeHooks]);
}
