import { FieldProps } from 'formik'
import React from 'react'
import { Form } from 'react-bootstrap'
import FormField, { FormFieldProps } from './'

interface InputFieldProps extends FormFieldProps, FieldProps {
  type: string
  placeholder?: string
  noAutoComplete?: boolean
}

const InputField: React.FunctionComponent<InputFieldProps> = props => {
  const { field, form, type, info, placeholder, noAutoComplete } = props
  if (!props.field.value) props.field.value = ''

  return (
    <FormField
      label={props.label}
      error={
        Boolean(form.touched && form.submitCount > 0)
          ? (form.errors[field.name] as string)
          : undefined
      }
      info={info}
    >
      <Form.Control
        type={type}
        {...field}
        placeholder={placeholder}
        autoComplete={noAutoComplete && 'off'}
      />
    </FormField>
  )
}

export default InputField
