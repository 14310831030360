import create, { SetState, GetState } from 'zustand'
import { devtools } from 'zustand/middleware'
import sessionSlice, { SessionSlice } from './session'
import tasksSlice, { TasksSlice } from './tasks'
import uiSlice, { UISlice } from './ui'

export interface StoreState {
  session: SessionSlice
  tasks: TasksSlice
  ui: UISlice
}

export type StoreSlice<T> = (set: SetState<StoreState>, get: GetState<StoreState>) => T

const store = (set: SetState<StoreState>, get: GetState<StoreState>) => ({
  session: { ...sessionSlice(set, get) },
  tasks: { ...tasksSlice(set, get) },
  ui: { ...uiSlice(set, get) },
})

const useStore = create<StoreState>(process.env.NODE_ENV === `production` ? store : devtools(store))

export default useStore
