import { format, isValid } from 'date-fns'
import React from 'react'
import { useSearchParams } from 'react-router-dom'

const useDayCurrentDate = (withUrlParams?: boolean): [Date, (d: Date) => void] => {
  const [searchParams, setSearchParams] = useSearchParams()
  const queryDate = searchParams.get('d')
  const urlDate = queryDate && new Date(queryDate)

  const [currentDate, setCurrentDate] = React.useState(
    withUrlParams && urlDate && isValid(urlDate) ? urlDate : new Date(),
  )

  React.useEffect(() => {
    withUrlParams && currentDate && setSearchParams({ d: format(currentDate, 'yyyy-MM-dd') })
  }, [currentDate])

  return [currentDate, setCurrentDate]
}

export default useDayCurrentDate
