import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from '../pages/home/home'
import OAuthCallback from '../pages/oauth/callback'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import Authenticated from './authenticated'
import Admin from '../pages/admin'
import PrivacyPolicy from '../pages/privacy-policy/privacy-policy'
import Welcome from '../pages/welcome/welcome'

const queryClient = new QueryClient()

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route
          path="/"
          element={
            <Authenticated>
              <Home />
            </Authenticated>
          }
        />
        <Route path="/oauth/callback" element={<OAuthCallback />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/welcome"
          element={
            <Authenticated>
              <Home />
            </Authenticated>
          }
        />
        <Route
          path="/me"
          element={
            <Authenticated>
              <Home />
            </Authenticated>
          }
        />
        <Route path="/admin/*" element={<Admin />} />
      </Routes>
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  )
}

export default App
