var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { CoreAdminContext } from 'ra-core';
import { defaultTheme } from './defaultTheme';
import { ThemeProvider } from './layout/Theme';
export var AdminContext = function (props) {
    var _a = props.theme, theme = _a === void 0 ? defaultTheme : _a, children = props.children, rest = __rest(props, ["theme", "children"]);
    return (React.createElement(CoreAdminContext, __assign({}, rest),
        React.createElement(ThemeProvider, { theme: theme }, children)));
};
AdminContext.displayName = 'AdminContext';
