import { addDays, format, isSameDay } from 'date-fns'
import React from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { upperFirst } from 'lodash'

interface DaySliderSelectorProps {
  date: Date
  onChange(date: Date): void
  actions?: React.ReactNode[]
}

const DaySliderSelector: React.FC<DaySliderSelectorProps> = ({ date, onChange, actions }) => {
  const isToday = React.useMemo(() => {
    return isSameDay(date, new Date())
  }, [date])
  return (
    <>
      <div>
        <ButtonGroup>
          <Button onClick={() => onChange(addDays(date, -1))} variant="light">
            <BsChevronLeft />
          </Button>
          <Button variant="light">{upperFirst(format(date, 'EEEE dd LLLL').toLowerCase())}</Button>
          <Button onClick={() => onChange(addDays(date, 1))} variant="light">
            <BsChevronRight />
          </Button>
        </ButtonGroup>
      </div>
      <div className="text-right">
        <Button
          variant="light"
          onClick={() => onChange(new Date())}
          size="sm"
          disabled={isToday}
          className="text-secondary"
        >
          {isToday ? 'Today' : 'Back to today'}
        </Button>
        {actions}
      </div>
    </>
  )
}

export default DaySliderSelector
