import React from 'react'
import { Button, ListGroup } from 'react-bootstrap'
import { TaskList } from '../../../../backend/src/models/days.model'
import Card from '../card/card'
import Task from '../tasks/item'
import DayTaskActions from './day-task-actions'

export interface Task {
  text?: string
  isCompleted?: boolean
  [key: string]: any
}

interface TasksListProps {
  title: string
  icon: React.ReactNode
  defaultLength: number
  onChange(tasks: Task[]): void
  onExport(task: Task, date?: Date): void
  tasks?: TaskList
  loadInProgress?: boolean
  date: Date
}

const DayTasksList: React.FC<TasksListProps> = ({
  title,
  icon,
  defaultLength,
  onChange,
  onExport,
  tasks,
  loadInProgress,
  date,
}) => {
  const [forceShowTasks, setForceShowTasks] = React.useState(false)

  const [indexToForceFocus, setIndexToForceFocus] = React.useState(-1)

  React.useEffect(() => {
    setForceShowTasks(false)
  }, [tasks])

  const handleChange = (field: 'isCompleted' | 'text', value: any, index: number) => {
    const currentState = tasks?.items[0]
      ? [...tasks.items]
      : [...Array(defaultLength).keys()].map(() => ({
          text: '',
          isCompleted: false,
        }))

    const newValue = currentState.map((task, i) => {
      if (index === i) return { ...task, [field]: value }
      return task
    })

    if (index >= currentState.length && field === 'text')
      newValue.push({ text: value, isCompleted: false })

    onChange(newValue)
  }

  const handleRemove = (task: Task) => {
    tasks && onChange([...tasks.items.filter(i => i._id !== task._id)])
  }

  const defaultListLength = React.useMemo(() => {
    let length = tasks?.items.length
    if (tasks && !tasks.items.some(i => !i.text)) length += 1
    if (!length || length < 4) length = 4
    return length
  }, [tasks])

  return (
    <Card title={title} icon={icon}>
      {tasks?.isCompleted && !forceShowTasks ? (
        <>
          <div className="d-flex align-items-center justify-content-center" style={{ height: 387 }}>
            <div>
              <img src={tasks.gif} style={{ maxWidth: '100%', maxHeight: 300 }} />
              <br />
              <Button variant="link" onClick={() => setForceShowTasks(true)}>
                Show to-do
              </Button>
            </div>
          </div>
        </>
      ) : (
        <ListGroup variant="flush">
          {[...Array(defaultListLength).keys()].map(index => {
            const task = tasks?.items[index]
            return (
              <ListGroup.Item key={task?._id || index}>
                <Task
                  task={task}
                  onIsCompletedChange={value => handleChange('isCompleted', value, index)}
                  onTextChange={value => handleChange('text', value, index)}
                  onEnterPress={() => {
                    //handleChange('text', value, index)
                    setIndexToForceFocus(index + 1)
                  }}
                  forceFocus={Boolean(indexToForceFocus === index)}
                  loadInProgress={loadInProgress}
                  actions={
                    task?.text && (
                      <DayTaskActions
                        task={task}
                        onRemove={handleRemove}
                        onExport={onExport}
                        date={date}
                      />
                    )
                  }
                />
              </ListGroup.Item>
            )
          })}
        </ListGroup>
      )}
    </Card>
  )
}

export default DayTasksList
