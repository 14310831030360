import React from 'react'
//@ts-ignore
import proIcon from '../../images/pro-icon.png'
//@ts-ignore
import privateIcon from '../../images/private-icon.png'
//@ts-ignore
import calendarIcon from '../../images/calendar-icon.png'
//@ts-ignore
import notesIcon from '../../images/notes-icon.png'

interface IconImgProps {
  icon: 'pro' | 'private' | 'calendar' | 'notes'
  height?: number
}

const IconImg: React.FC<IconImgProps> = ({ icon, height }) => {
  const imgSrc = React.useMemo(() => {
    if (icon === 'pro') return proIcon
    if (icon === 'private') return privateIcon
    if (icon === 'calendar') return calendarIcon
    if (icon === 'notes') return notesIcon
  }, [icon])
  return <img src={imgSrc} style={{ height }} />
}

export default IconImg
