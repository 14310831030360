import { CustomMethods, feathers } from '@feathersjs/feathers'
import rest, { RestService } from '@feathersjs/rest-client'
import axios from 'axios'
import auth from '@feathersjs/authentication-client'
import { ExportTaskData, ImportTaskData } from '../../../backend/src/services/days/days.class'
import {
  RequestMagicCodeData,
  RequestMagicCodeRes,
} from '../../../backend/src/services/users/users.class'
import { ListEventsData } from '../../../backend/src/services/cronofy/cronofy.class'
import { Day } from '../../../backend/src/models/days.model'

export type ExportTaskPayload = Omit<ExportTaskData, 'userId'>

export type ServiceTypes = {
  users: RestService &
    CustomMethods<{ requestMagicCode: [RequestMagicCodeData, RequestMagicCodeRes] }>
  days: RestService &
    CustomMethods<{ importTask: [ImportTaskData, Day]; exportTask: [ExportTaskPayload, Day] }>
  tasks: RestService
  cronofy: RestService &
    CustomMethods<{
      listEvents: [ListEventsData, any]
    }>
  podcasts: RestService &
    CustomMethods<{
      getByUserAndDate: [{ userId: string; date: Date }, any]
      getUserDailyPodcast: [{ userId: string }, any]
      setSeenForUser: [{ userId: string }, any]
    }>
}

const app = feathers<ServiceTypes>()

// Connect to a different URL
const restClient = rest(process.env.API_HOST_NAME)

// Configure an AJAX library (see below) with that client
app.configure(restClient.axios(axios))
app.configure(auth())

app.service('days').methods('importTask')
app.service('days').methods('exportTask')
app.service('cronofy').methods('listEvents')
app.service('podcasts').methods('getByUserAndDate')
app.service('podcasts').methods('getUserDailyPodcast')
app.service('podcasts').methods('setSeenForUser')
app.service('users').methods('requestMagicCode')

export default app
