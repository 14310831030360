import React from 'react'
import { Card as BsCard } from 'react-bootstrap'
import Icon from '../icon/icon'

interface CardProps {
  title: string
  icon?: React.ReactNode
  style?: any
}

const Card: React.FC<CardProps> = ({ title, icon, children, style }) => {
  return (
    <BsCard style={style}>
      {/*<BsCard.Header>
        {icon && false && (
          <h5 style={{ width: 16, display: 'inline-block' }} className="me-1">
            {icon}
          </h5>
        )}
        <h5 className="me-1" style={{ display: 'inline-block' }}>
          {title}
        </h5>
      </BsCard.Header>*/}
      <BsCard.Body>
        <div className="d-flex align-items-center">
          <div className="p-2 pe-0 text-secondary" style={{ marginBottom: 5 }}>
            {icon}
          </div>
          <div className="me-auto p-2">
            <h5 className="m-0" style={{ display: 'inline-block' }}>
              {title}
            </h5>
          </div>
        </div>
        {children}
      </BsCard.Body>
    </BsCard>
  )
}

export default Card
