import React from 'react'
import LoginForm from '../login/login-form'
import SideModal from '../side-modal/side-modal'
import { SideModalCommonProps } from './types'
//@ts-ignore
import logo from '../../images/komit-logo.png'

type LoginSideModalProps = SideModalCommonProps

const LoginSideModal: React.FC<LoginSideModalProps> = ({ isOpen, onClose }) => {
  return (
    <SideModal isOpen={isOpen} onClose={onClose} showOverlay>
      <div
        className="text-center d-flex align-items-center justify-content-center"
        style={{ height: '80vh' }}
      >
        <div>
          <LoginForm />
        </div>
      </div>
    </SideModal>
  )
}

export default LoginSideModal
