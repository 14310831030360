import { Field, Formik } from 'formik'
import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { useMutation } from 'react-query'
import app from '../../core/feathers'
import { useCustomMutation } from '../../core/queries'
import useStore from '../../store'
import DigitsInputField from '../form/fields/digits-input'
import InputField from '../form/fields/input'

interface LoginFormProps {}

const LoginForm: React.FC<LoginFormProps> = ({}) => {
  const mutation = useCustomMutation<{ email: string }>('users', 'requestMagicCode')
  const setCurrentuser = useStore(state => state.session.setCurrentuser)
  const loginMutation = useMutation(
    async (data: any): Promise<any> => {
      return app.authenticate({
        strategy: 'magicCode',
        ...data,
      })
    },
    {
      onSuccess(data) {
        setCurrentuser(data.user)
      },
    },
  )

  const [usedEmail, setUsedEmail] = React.useState<string>()

  React.useEffect(() => {
    mutation.data?.email && setUsedEmail(mutation.data?.email)
  }, [mutation.data?.email])

  const currentUser = useStore(state => state.session.currentUser)

  if (mutation.isLoading) return <div className="spinner-border" role="status"></div>

  if (mutation.isSuccess && mutation.data?.token)
    return (
      <div>
        <div className="fs-2 mb-5">You are almost in...</div>

        <Formik
          initialValues={{ code: '' }}
          enableReinitialize
          validate={values => {
            const errors: any = {}
            if (!values.code) errors.code = 'Required field'
            return errors
          }}
          onSubmit={(values, { resetForm }) => {
            loginMutation.mutate({
              ...values,
              userToken: mutation.data?.token as string,
            })
            //resetForm({})
          }}
        >
          {props => (
            <>
              <div className="mb-4">
                An email has been sent to <br />
                <strong>{mutation.data?.email}</strong>
                <br /> with a <strong>4-digit code</strong>.
                <div className="mt-3">
                  <a
                    className="text-decoration-none"
                    style={{ fontSize: 12, cursor: 'pointer' }}
                    onClick={e => {
                      e.stopPropagation()
                      props.setErrors({})
                      loginMutation.reset()
                      mutation.reset()
                    }}
                  >
                    Click here to resend it or to edit your email.
                  </a>
                </div>
              </div>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  props.handleSubmit()
                }}
              >
                <div style={{ minHeight: 80 }} className="text-center">
                  <Field
                    name="code"
                    //label="4 digits Code"
                    component={DigitsInputField}
                    length={4}
                    noAutoComplete
                  />
                  {loginMutation.isError && (
                    <div>
                      <div className="text-danger mb-3">Code not recognized</div>
                      <div className="text-light">Retry</div>
                    </div>
                  )}
                  <Button type="submit" className="mt-3">
                    Login
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    )

  return (
    <div>
      <div className="fs-2 mb-5">Sign up / login</div>
      <Formik
        initialValues={{
          email: usedEmail || '',
          anonymousId: currentUser?.kind === 'anonymous' && currentUser._id,
        }}
        enableReinitialize
        validate={values => {
          const errors: any = {}
          if (!values.email) errors.text = 'Required field'
          return errors
        }}
        onSubmit={(values, { resetForm }) => {
          mutation.mutate(values)
          resetForm({})
        }}
      >
        {props => (
          <Form
            onSubmit={e => {
              e.preventDefault()
              props.handleSubmit()
            }}
          >
            <div style={{ minHeight: 80 }}>
              <Field
                name="email"
                type="email"
                component={InputField}
                info="A 4-digit code will be emailed to you."
                placeholder="your email..."
                noAutoComplete
              />
              <Button className="mt-3" type="submit">
                Sign up or login
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default LoginForm
