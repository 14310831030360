import React from 'react'
import { getHours, isSameDay } from 'date-fns'
import { useQuery } from 'react-query'
import { Podcast } from '../../../backend/src/models/podcasts.model'
import app from '../core/feathers'
import { SessionSlice } from '../store/session'
import useStore from '../store'

const localStorageKey = 'last-podcast-seen-date'

export function useTodayPodcast(
  currentUser: SessionSlice['currentUser'],
): [Podcast | undefined, boolean, () => void, () => void] {
  //Is visible state
  const [isPodcastIsVisible, setIsPodcastIsVisible] = React.useState(false)

  //Hide podcast function (set visible to false and add data to local storage)
  const hidePodcast = () => {
    if (isPodcastIsVisible) {
      setIsPodcastIsVisible(false)
      localStorage.setItem(localStorageKey, new Date().toString())
    }
  }

  //Local storage data (date of last podcast seen)
  const podcastLastSeen = localStorage.getItem(localStorageKey)
  const podcastLastSeenDate = podcastLastSeen && new Date(podcastLastSeen)

  //Fetch today postcast
  const podcastQuery =
    currentUser &&
    useQuery<any, unknown, Podcast, ['podcast', any]>(
      ['podcast', { date: new Date().toDateString(), userId: currentUser?._id }],
      async () => {
        return await app.service('podcasts').getUserDailyPodcast(
          { userId: currentUser?._id },
          //@ts-ignore
          { headers: { Authorization: await app.authentication.getAccessToken() } },
        )
      },
    )

  //Effect to handle is podcast visible
  React.useEffect(() => {
    if (
      currentUser &&
      podcastQuery?.data &&
      getHours(new Date()) >= 8 &&
      (!podcastLastSeenDate || !isSameDay(podcastLastSeenDate, new Date())) &&
      !isSameDay(new Date(currentUser?.dailyVideos.date), new Date())
    )
      setIsPodcastIsVisible(true)
  }, [currentUser, podcastQuery?.data])

  return [podcastQuery?.data, isPodcastIsVisible, hidePodcast, () => setIsPodcastIsVisible(true)]
}
