import React from 'react'
import { User } from '../../../../backend/src/models/users.model'
import useStore from '../../store'
import SideModal from '../side-modal/side-modal'
import ProfileForm from '../users/profile-form'
import { SideModalCommonProps } from './types'

type UserProfileSideModalProps = SideModalCommonProps

const UserProfileSideModal: React.FC<UserProfileSideModalProps> = ({ isOpen, onClose }) => {
  const currentUser = useStore(state => state.session.currentUser)

  if (!currentUser || currentUser.kind === 'anonymous') return <></>
  return (
    <SideModal
      isOpen={isOpen}
      onClose={onClose}
      title={''}
      showOverlay
      hideCloseButton={Boolean(!currentUser.firstName)}
    >
      <div
        className="text-center d-flex align-items-center justify-content-center"
        style={{ height: '80vh' }}
      >
        <div>
          <div>
            <div className="fs-2 mb-5">
              {!currentUser.firstName ? 'One more thing' : 'Your profile'}
            </div>
            {!currentUser.firstName && <div className="mb-4">What’s your name?</div>}
          </div>
          <ProfileForm user={currentUser as User} />
        </div>
      </div>
    </SideModal>
  )
}

export default UserProfileSideModal
