import { authClient, restClient } from 'ra-data-feathers'
import React from 'react'
import { Admin as ReactAdmin, Resource, defaultTheme } from 'react-admin'
import { FaCalendarDay, FaUsers, FaYoutube } from 'react-icons/fa'
import DaysList from '../../components/admin/days/list'
import PodcastsCreate from '../../components/admin/podcasts/create'
import PodcastsList from '../../components/admin/podcasts/list'
import PodcastsEdit from '../../components/admin/podcasts/edit'
import UserStatsList from '../../components/admin/users-stats/list'
import Authenticated from '../../containers/authenticated'
import app from '../../core/feathers'

const restClientOptions = {
  id: '_id', // In this example, the database uses '_id' rather than 'id'
  usePatch: true, // Use PATCH instead of PUT for updates
  resources: {
    id: '_id',
  },
}

const authClientOptions = {
  usernameField: 'email', // Our example database might use 'username' rather than 'email'
  authenticate: {
    // Options included in calls to Feathers client.authenticate
    strategy: 'jwt', // The authentication strategy Feathers should use
  },
  logoutOnForbidden: false,
  // permissionsField: 'userroles', // Use the 'userroles' field on the JWT as the users role
  //redirectTo: '/signin', // Our example login form might be at '/signin', redirect here if AUTH_CHECK fails
}

const Admin: React.FC = ({}) => {
  return (
    <Authenticated forAdmin>
      <ReactAdmin
        dataProvider={restClient(app, restClientOptions)}
        basename="/admin"
        authProvider={authClient(app, authClientOptions)}
        theme={lightTheme}
        loginPage={() => {
          window.location.href = '/'
          return <></>
        }}
      >
        {permissions => [
          // <Resource name="users" list={UsersList} />,
          <Resource
            name="users-stats"
            list={UserStatsList}
            icon={FaUsers}
            options={{ label: 'Users' }}
          />,
          <Resource name="days" list={DaysList} icon={FaCalendarDay} />,
          <Resource
            name="podcasts"
            list={PodcastsList}
            create={PodcastsCreate}
            icon={FaYoutube}
            edit={PodcastsEdit}
          />,
        ]}
      </ReactAdmin>
    </Authenticated>
  )
}

export default Admin

export const darkTheme = {
  palette: {
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#FBBA72',
    },
    mode: 'dark' as const, // Switching the dark mode on is a single property value change.
  },
  sidebar: {
    width: 200,
  },
  components: {
    ...defaultTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: '3px solid #000',
          '&.RaMenuItemLink-active': {
            borderLeft: '3px solid #90caf9',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: '#ffffffb3',
          backgroundColor: '#616161e6',
        },
      },
    },
  },
}

export const lightTheme = {
  palette: {
    primary: {
      main: '#4f3cc9',
    },
    secondary: {
      light: '#5f5fc4',
      main: '#283593',
      dark: '#001064',
      contrastText: '#fff',
    },
    background: {
      default: '#fcfcfe',
    },
    mode: 'light' as const,
  },
  shape: {
    borderRadius: 10,
  },
  sidebar: {
    width: 200,
  },
  components: {
    ...defaultTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: '3px solid #fff',
          '&.RaMenuItemLink-active': {
            borderLeft: '3px solid #4f3cc9',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: 'none',
        },
        root: {
          border: '1px solid #e0e0e3',
          backgroundClip: 'padding-box',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: '#808080',
          backgroundColor: '#fff',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#f5f5f5',
        },
        barColorPrimary: {
          backgroundColor: '#d7d7d7',
        },
      },
    },
  },
}
