import React from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import { FaCalendarAlt, FaTimes, FaTrash } from 'react-icons/fa'
import { Task } from './day-tasks-list'
import { addDays } from 'date-fns'

interface DayTaskActionsProps {
  task: Task
  date: Date
  onRemove(task: Task): void
  onExport(task: Task, date?: Date)
}

const DayTaskActions: React.FC<DayTaskActionsProps> = ({ task, onRemove, onExport, date }) => {
  const [showExportOptions, setShowExportOptions] = React.useState(false)

  return (
    <>
      {!task.isCompleted && (
        <>
          {showExportOptions ? (
            <>
              <ButtonGroup>
                <Button
                  size="sm"
                  variant="light"
                  onClick={() => setShowExportOptions(!showExportOptions)}
                >
                  <FaTimes />
                </Button>
                <Button
                  size="sm"
                  variant="light"
                  onClick={() => onExport(task, addDays(new Date(date), 1))}
                >
                  Tomorrow
                </Button>
                <Button size="sm" variant="light" onClick={() => onExport(task)}>
                  Later
                </Button>
              </ButtonGroup>
            </>
          ) : (
            <Button
              size="sm"
              variant="light"
              onClick={() => setShowExportOptions(!showExportOptions)}
            >
              <FaCalendarAlt />
            </Button>
          )}
        </>
      )}
      {!showExportOptions && (
        <>
          <br />
          <Button size="sm" className="mt-1" variant="light" onClick={() => onRemove(task)}>
            <FaTrash />
          </Button>
        </>
      )}
    </>
  )
}

export default DayTaskActions
