import produce from 'immer'
import { StoreSlice, StoreState } from '.'
import app from '../core/feathers'
import { AnonymousUser, User } from '../../../backend/src/models/users.model'

export interface SessionSlice {
  logged: boolean
  userType?: 'anonymous' | 'known'
  currentUser?: AnonymousUser | User
  token?: string
  isAuth(): Promise<void>
  magicCodeAuth(data: {
    userToken: string
    code: string
    firstName?: string
    lastName?: string
  }): Promise<void>
  anonymousAuth(): Promise<void>
  setCurrentuser(user?: AnonymousUser | User): void
  replaceToken(token: string): Promise<void>
  logout(): Promise<void>
}

const sessionSlice: StoreSlice<SessionSlice> = (set, get) => ({
  logged: false,
  current: undefined,
  token: undefined,
  replaceToken: async token => {
    await app.authentication.setAccessToken(token)
    await app.reAuthenticate()
    //@ts-ignore
    window.location = '/'
  },
  magicCodeAuth: async data => {
    const res = await app.authenticate({
      strategy: 'magicCode',
      ...data,
    })
    console.log(res)
    if (res && res.user && res.user._id) get().session.setCurrentuser(res.user)
  },
  isAuth: async () => {
    try {
      const res = await app.reAuthenticate()
      get().session.setCurrentuser(res.user)
    } catch (err) {
      console.error(err)
    }
  },
  anonymousAuth: async () => {
    try {
      const res = await app.reAuthenticate()
      get().session.setCurrentuser(res.user)
    } catch (err) {
      const anonymousId = localStorage.getItem('anonymous-id')
      const res = await app.authenticate({
        strategy: 'anonymous',
        token: anonymousId,
      })
      if (res && res.user && res.user._id) await get().session.setCurrentuser(res.user)
    }
  },
  setCurrentuser: async user => {
    console.log(user)
    if (user && user.kind === 'anonymous') localStorage.setItem('anonymous-id', user._id)
    const accessToken = await app.authentication.getAccessToken()
    set(
      produce((state: StoreState) => {
        state.session.token = accessToken
        state.session.currentUser = user
        state.session.logged = Boolean(user)
        state.session.userType = user ? user.kind : undefined
      }),
    )
  },
  logout: async () => {
    await app.authentication.logout()
    await get().session.setCurrentuser()
    //@ts-ignore
    window.location = '/'
  },
})

export default sessionSlice
