import React, { Ref } from 'react'
import { Col, Form, Row, Spinner } from 'react-bootstrap'
import reactTextareaAutosize from 'react-textarea-autosize'
import { Task } from '../../../../backend/src/models/tasks.model'

interface TaskProps {
  task?: Omit<Task, 'user'> //TODO: type!
  onIsCompletedChange?(value: boolean, task: Omit<Task, 'user'>): void
  onTextChange?(value: string, task: Omit<Task, 'user'>): void
  onEnterPress?(value: string, task: Omit<Task, 'user'>): void
  inputRef?: Ref<any>
  disabled?: boolean
  actions?: React.ReactNode
  forceFocus?: boolean
  loadInProgress?: boolean
}

const Task: React.FC<TaskProps> = ({
  task,
  //inputRef,
  disabled,
  onIsCompletedChange,
  onTextChange,
  onEnterPress,
  actions,
  forceFocus,
  loadInProgress,
}) => {
  const inputRef = React.useRef(null)

  React.useEffect(() => {
    if (forceFocus && inputRef?.current) inputRef.current.focus()
  }, [inputRef, forceFocus])

  return (
    <Row className="show-hover-box">
      {loadInProgress ? (
        <Col md={12} style={{ minHeight: 80 }} className="d-flex align-items-center">
          <Spinner animation="border" className="text-light" />
        </Col>
      ) : (
        <>
          <Col md={1} xs={1} className="d-flex align-items-center ps-0">
            <Form.Check type={'checkbox'}>
              <Form.Check.Input
                type={'checkbox'}
                isValid
                disabled={Boolean(disabled || !task?.text)}
                defaultChecked={task?.isCompleted}
                onChange={e => {
                  onIsCompletedChange && onIsCompletedChange(e.target.checked, task)
                }}
              />
            </Form.Check>
          </Col>
          <Col md={11} xs={11} style={{ minHeight: 80 }} className="d-flex align-items-center pe-0">
            <Form.Control
              as={reactTextareaAutosize}
              placeholder="Today, I komit to..."
              defaultValue={task?.text || ''}
              onBlur={e => onTextChange && onTextChange(e.target.value, task)}
              maxRows={6}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  inputRef.current.blur()
                  //@ts-ignore
                  onEnterPress && onEnterPress(e.target.value, task)
                }
              }}
              disabled={disabled || task?.isCompleted}
              style={{
                border: 0,
                resize: 'none',
                backgroundColor: 'transparent',
                textDecoration: task?.isCompleted ? 'line-through' : '',
                fontWeight: task?.text ? 500 : 'normal',
                paddingLeft: 0,
                paddingRight: 0,
              }}
              ref={inputRef}
            />
          </Col>
          <div
            style={{
              position: 'absolute',
              right: -22,
              top: 0,
              textAlign: 'right',
              padding: 0,
              width: 'auto',
            }}
            className="show-hover-box_item"
          >
            {actions}
          </div>
        </>
      )}
    </Row>
  )
}

export default Task
