import React from 'react'
import { Edit, NumberInput, required, SimpleForm, TextInput, useRecordContext } from 'react-admin'
import YouTube from 'react-youtube'

interface PodcastEditProps {}

const PodcastEdit: React.FC<PodcastEditProps> = ({}) => {
  const record = useRecordContext()

  //TODO: one component for create & edit
  return (
    <>
      <Edit mutationMode="optimistic">
        <SimpleForm>
          <NumberInput source="userDay" validate={[required()]} fullWidth />
          <TextInput source="youtubeId" validate={[required()]} label="Youtube video ID" />
        </SimpleForm>
      </Edit>
    </>
  )
}

export default PodcastEdit
