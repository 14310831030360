import produce from 'immer'
import { StoreSlice, StoreState } from '.'
import { Task } from '../../../backend/src/models/tasks.model'

export interface TasksFilters {
  kind: Task['kind']
  isCompleted: boolean
  user?: string
}

export interface TasksSlice {
  filters: TasksFilters
  setFilter(key: keyof TasksFilters, value?: any): Promise<void>
}

const tasksSlice: StoreSlice<TasksSlice> = (set, get) => ({
  all: [],
  currentTask: undefined,
  filters: {
    kind: 'pro',
    isCompleted: false,
    $sort: {
      createdAt: -1,
    },
  },
  setFilter: async (key, value) => {
    set(
      produce((state: StoreState) => {
        state.tasks.filters = {
          ...state.tasks.filters,
          [key]: value,
          user: get().session.currentUser?._id,
        }
      }),
    )
  },
})

export default tasksSlice
