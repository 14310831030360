import { Field, Form, Formik } from 'formik'
import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { User } from '../../../../backend/src/models/users.model'
import { useFeathersMutation } from '../../core/queries'
import useStore from '../../store'
import InputField from '../form/fields/input'

interface ProfileFormProps {
  user: User
}

const ProfileForm: React.FC<ProfileFormProps> = ({ user }) => {
  const mutation = useFeathersMutation<User, Partial<User>>('users', 'patch')
  const currentUser = useStore(state => state.session.currentUser)

  const isNew = React.useMemo(() => {
    return !user.firstName || !user.lastName
  }, [])

  React.useEffect(() => {
    if (mutation.status === 'success' && currentUser._id === mutation.data._id) {
      isNew && window.location.replace('/')
    }
  }, [mutation.status])

  return (
    <Formik
      initialValues={{
        ...user,
        _id: user._id,
        firstName: user.firstName,
        //lastName: user.lastName,
        kind: user.kind,
      }}
      enableReinitialize
      validate={values => {
        const errors: any = {}
        if (!values.firstName) errors.firstName = 'Required field'
        //if (!values.lastName) errors.lastName = 'Required field'
        return errors
      }}
      onSubmit={(values, { resetForm }) => {
        mutation.mutate(values)
        resetForm({})
      }}
    >
      {props => (
        <Form>
          <div style={{ minHeight: 80 }}>
            <Field
              name="firstName"
              component={InputField}
              placeholder="your first name..."
              noAutoComplete
            />
            {/*<Field
              name="lastName"
              component={InputField}
              placeholder="your last name..."
              noAutoComplete
      /> */}
            <Button onClick={props.handleSubmit} className="mt-3">
              Enter
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ProfileForm
