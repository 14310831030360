import React from 'react'
import { useLocation } from 'react-router-dom'
import ReactSlidingPane from 'react-sliding-pane'

interface SideModalProps {
  isOpen: boolean
  onClose(): void
  title?: React.ReactNode
  hideCloseButton?: boolean
  width?: string
  showOverlay?: boolean
}

const SideModal: React.FC<SideModalProps> = ({
  isOpen,
  onClose,
  children,
  title,
  hideCloseButton,
  width,
  showOverlay,
}) => {
  const location = useLocation()
  const lastOpenDateTime = React.useMemo(() => new Date().getTime(), [isOpen, location.pathname])
  React.useEffect(() => {
    const onClick = e => {
      if (
        !e.target.closest('[data-side-modal]') &&
        !e.target.closest('.ReactModalPortal') &&
        new Date().getTime() - lastOpenDateTime >= 1000 &&
        !hideCloseButton
      ) {
        onClose()
      }
    }
    document.body.addEventListener('click', onClick)

    return () => {
      document.body.removeEventListener('click', onClick)
    }
  }, [lastOpenDateTime])
  return (
    <ReactSlidingPane
      className="side-modal"
      overlayClassName={showOverlay ? 'side-modal-overlay show' : 'side-modal-overlay'}
      isOpen={isOpen}
      onRequestClose={!hideCloseButton ? onClose : () => null}
      title={title}
      width={width || '30%'}
      closeIcon={hideCloseButton && <></>}
    >
      {children}
    </ReactSlidingPane>
  )
}

export default SideModal
