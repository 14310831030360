var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useContext, useMemo } from 'react';
import { useList } from '../../controller';
import { ChoicesContext } from './ChoicesContext';
export var useChoicesContext = function (options) {
    if (options === void 0) { options = {}; }
    var context = useContext(ChoicesContext);
    var _a = useList({ data: options.choices }), data = _a.data, list = __rest(_a, ["data"]);
    var result = useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21;
        return ({
            allChoices: (_a = context === null || context === void 0 ? void 0 : context.allChoices) !== null && _a !== void 0 ? _a : data,
            availableChoices: (_c = (_b = context === null || context === void 0 ? void 0 : context.availableChoices) !== null && _b !== void 0 ? _b : options.availableChoices) !== null && _c !== void 0 ? _c : data,
            selectedChoices: (_e = (_d = context === null || context === void 0 ? void 0 : context.selectedChoices) !== null && _d !== void 0 ? _d : options.selectedChoices) !== null && _e !== void 0 ? _e : data,
            displayedFilters: (_g = (_f = context === null || context === void 0 ? void 0 : context.displayedFilters) !== null && _f !== void 0 ? _f : options.displayedFilters) !== null && _g !== void 0 ? _g : list.displayedFilters,
            error: (_j = (_h = context === null || context === void 0 ? void 0 : context.error) !== null && _h !== void 0 ? _h : options.error) !== null && _j !== void 0 ? _j : list.error,
            filter: (_l = (_k = context === null || context === void 0 ? void 0 : context.filter) !== null && _k !== void 0 ? _k : options.filter) !== null && _l !== void 0 ? _l : list.filter,
            filterValues: (_o = (_m = context === null || context === void 0 ? void 0 : context.filterValues) !== null && _m !== void 0 ? _m : options.filterValues) !== null && _o !== void 0 ? _o : list.filterValues,
            hasNextPage: (_q = (_p = context === null || context === void 0 ? void 0 : context.hasNextPage) !== null && _p !== void 0 ? _p : options.hasNextPage) !== null && _q !== void 0 ? _q : list.hasNextPage,
            hasPreviousPage: (_s = (_r = context === null || context === void 0 ? void 0 : context.hasPreviousPage) !== null && _r !== void 0 ? _r : options.hasPreviousPage) !== null && _s !== void 0 ? _s : list.hasPreviousPage,
            hideFilter: (_u = (_t = context === null || context === void 0 ? void 0 : context.hideFilter) !== null && _t !== void 0 ? _t : options.hideFilter) !== null && _u !== void 0 ? _u : list.hideFilter,
            isFetching: (_w = (_v = context === null || context === void 0 ? void 0 : context.isFetching) !== null && _v !== void 0 ? _v : options.isFetching) !== null && _w !== void 0 ? _w : list.isFetching,
            isLoading: (_y = (_x = context === null || context === void 0 ? void 0 : context.isLoading) !== null && _x !== void 0 ? _x : options.isLoading) !== null && _y !== void 0 ? _y : list.isLoading,
            page: (_0 = (_z = context === null || context === void 0 ? void 0 : context.page) !== null && _z !== void 0 ? _z : options.page) !== null && _0 !== void 0 ? _0 : list.page,
            perPage: (_2 = (_1 = context === null || context === void 0 ? void 0 : context.perPage) !== null && _1 !== void 0 ? _1 : options.perPage) !== null && _2 !== void 0 ? _2 : list.perPage,
            refetch: (_4 = (_3 = context === null || context === void 0 ? void 0 : context.refetch) !== null && _3 !== void 0 ? _3 : options.refetch) !== null && _4 !== void 0 ? _4 : list.refetch,
            resource: (_6 = (_5 = context === null || context === void 0 ? void 0 : context.resource) !== null && _5 !== void 0 ? _5 : options.resource) !== null && _6 !== void 0 ? _6 : list.resource,
            setFilters: (_8 = (_7 = context === null || context === void 0 ? void 0 : context.setFilters) !== null && _7 !== void 0 ? _7 : options.setFilters) !== null && _8 !== void 0 ? _8 : list.setFilters,
            setPage: (_10 = (_9 = context === null || context === void 0 ? void 0 : context.setPage) !== null && _9 !== void 0 ? _9 : options.setPage) !== null && _10 !== void 0 ? _10 : list.setPage,
            setPerPage: (_12 = (_11 = context === null || context === void 0 ? void 0 : context.setPerPage) !== null && _11 !== void 0 ? _11 : options.setPerPage) !== null && _12 !== void 0 ? _12 : list.setPerPage,
            setSort: (_14 = (_13 = context === null || context === void 0 ? void 0 : context.setSort) !== null && _13 !== void 0 ? _13 : options.setSort) !== null && _14 !== void 0 ? _14 : list.setSort,
            showFilter: (_16 = (_15 = context === null || context === void 0 ? void 0 : context.showFilter) !== null && _15 !== void 0 ? _15 : options.showFilter) !== null && _16 !== void 0 ? _16 : list.showFilter,
            sort: (_18 = (_17 = context === null || context === void 0 ? void 0 : context.sort) !== null && _17 !== void 0 ? _17 : options.sort) !== null && _18 !== void 0 ? _18 : list.sort,
            source: (_19 = context === null || context === void 0 ? void 0 : context.source) !== null && _19 !== void 0 ? _19 : options.source,
            total: (_21 = (_20 = context === null || context === void 0 ? void 0 : context.total) !== null && _20 !== void 0 ? _20 : options.total) !== null && _21 !== void 0 ? _21 : list.total,
        });
    }, [context, data, list, options]);
    return result;
};
