import React from 'react'
import { Button, ListGroup } from 'react-bootstrap'
import useStore from '../../store'
import Task from '../tasks/item'
import Icon from '../icon/icon'
import { GiFlagObjective } from 'react-icons/gi'
import { useQuery } from 'react-query'
import { fetch, useCustomMutation, useFeathersMutation } from '../../core/queries'
import { Task as ITask } from '../../../../backend/src/models/tasks.model'
import { ImportTaskPayload } from '../../../../backend/src/services/days/hooks/load-day-and-task-for-import'
import { Paginated } from '@feathersjs/feathers/lib'
import { Day } from '../../../../backend/src/models/days.model'

interface TasksList {
  date: Date
  currentDay?: Day
}

const TasksList: React.FC<TasksList> = ({ date, currentDay }) => {
  const filters = useStore(state => state.tasks.filters)
  const tasksQuery = useQuery<any, unknown, Paginated<ITask>, ['tasks', any]>(
    ['tasks', filters],
    fetch,
  )
  const mutation = useFeathersMutation<Partial<ITask>>('tasks', 'patch', {
    otherQueriesToInvalidates: ['tasks'],
  })
  const importTaskMutation = useCustomMutation<ImportTaskPayload>('days', 'importTask', {
    otherQueriesToInvalidates: ['tasks'],
  })
  const createDayMutation = useFeathersMutation<Day, Partial<Day>>('days', 'create')

  const handleImportTask = async (task: ITask) => {
    let day = currentDay
    if (!currentDay) day = await createDayMutation.mutateAsync({ date })
    importTaskMutation.mutate({
      task: task._id,
      day: day._id,
    })
  }

  return (
    <ListGroup variant="flush">
      {tasksQuery.data &&
        tasksQuery.data.data.map(task => (
          <div key={task._id}>
            <ListGroup.Item style={{ border: 'none' }}>
              <Task
                task={task}
                onTextChange={text => mutation.mutate({ _id: task._id, text })}
                onEnterPress={text => mutation.mutate({ _id: task._id, text })}
                onIsCompletedChange={isCompleted =>
                  mutation.mutateAsync({ _id: task._id, isCompleted })
                }
                actions={
                  !task.isCompleted && (
                    <>
                      <Button
                        size="sm"
                        className="me-1"
                        variant="light"
                        onClick={() => handleImportTask(task)}
                      >
                        <Icon icon={<GiFlagObjective />} text={'Add'} />
                      </Button>
                    </>
                  )
                }
              />
            </ListGroup.Item>
            <hr />
          </div>
        ))}
    </ListGroup>
  )
}

export default TasksList
